import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66ffa155"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "main-content" }
const _hoisted_4 = { class: "main-inner-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewHeader = _resolveComponent("NewHeader")!
  const _component_LoginButton = _resolveComponent("LoginButton")!
  const _component_NewFooter = _resolveComponent("NewFooter")!
  const _component_AccountInfoPopup = _resolveComponent("AccountInfoPopup")!
  const _component_WithdrawalPopup = _resolveComponent("WithdrawalPopup")!
  const _component_MobileAppLinkPopup = _resolveComponent("MobileAppLinkPopup")!
  const _component_PrivacyPolicyPopup = _resolveComponent("PrivacyPolicyPopup")!
  const _component_CyberSecurityPopup = _resolveComponent("CyberSecurityPopup")!
  const _component_NoticePopup = _resolveComponent("NoticePopup")!
  const _component_IdleTimePopup = _resolveComponent("IdleTimePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["main", { 'main-default-bg': _ctx.isImageLoaded }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.bgImage,
          class: "bg-img",
          alt: "Background",
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoadImage && _ctx.onLoadImage(...args)))
        }, null, 40, _hoisted_2),
        _createVNode(_component_NewHeader, {
          "is-login": _ctx.isLogin,
          onGoLoginPage: _ctx.goLoginPage,
          onChangeLanguage: _ctx.changeLanguage,
          onOpenAccountInfo: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPopup(_ctx.LANDING_PAGE_POPUP.ACCOUNT_INFO))),
          onLogout: _ctx.onLogout
        }, null, 8, ["is-login", "onGoLoginPage", "onChangeLanguage", "onLogout"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('message.landing_page_title')), 1),
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t('common.hems')) + ", Q.OMMAND", 1),
            _createVNode(_component_LoginButton, {
              "is-login": _ctx.isLogin,
              onClick: _ctx.onClickLoginButton
            }, null, 8, ["is-login", "onClick"])
          ])
        ])
      ]),
      _createVNode(_component_NewFooter, {
        onOpenAppDownloadPopup: _ctx.openAppDownloadPopup,
        onOpenPrivacyPopup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openPopup(_ctx.LANDING_PAGE_POPUP.PRIVACY))),
        onOpenCyberSecurityPopup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openPopup(_ctx.LANDING_PAGE_POPUP.CYBER_SECURITY)))
      }, null, 8, ["onOpenAppDownloadPopup"])
    ], 2),
    (_ctx.popupState.isOpenAccountInfo)
      ? (_openBlock(), _createBlock(_component_AccountInfoPopup, {
          key: 0,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closePopup(_ctx.LANDING_PAGE_POPUP.ACCOUNT_INFO))),
          onWithdrawal: _ctx.openWithdrawalPopup
        }, null, 8, ["onWithdrawal"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.isOpenWithdrawal)
      ? (_openBlock(), _createBlock(_component_WithdrawalPopup, {
          key: 1,
          "lang-cd": _ctx.languageCode,
          onLogout: _ctx.onLogout,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closePopup(_ctx.LANDING_PAGE_POPUP.WITHDRAWAL)))
        }, null, 8, ["lang-cd", "onLogout"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.isOpenAppDownload)
      ? (_openBlock(), _createBlock(_component_MobileAppLinkPopup, {
          key: 2,
          "store-type": _ctx.appStoreType,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closePopup(_ctx.LANDING_PAGE_POPUP.APP_DOWNLOAD)))
        }, null, 8, ["store-type"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.isOpenPrivacy)
      ? (_openBlock(), _createBlock(_component_PrivacyPolicyPopup, {
          key: 3,
          onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closePopup(_ctx.LANDING_PAGE_POPUP.PRIVACY)))
        }))
      : _createCommentVNode("", true),
    (_ctx.popupState.isOpenCyberSecurity)
      ? (_openBlock(), _createBlock(_component_CyberSecurityPopup, {
          key: 4,
          onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.closePopup(_ctx.LANDING_PAGE_POPUP.CYBER_SECURITY)))
        }))
      : _createCommentVNode("", true),
    (_ctx.popupState.isOpenNotice && _ctx.noticeInfo)
      ? (_openBlock(), _createBlock(_component_NoticePopup, {
          key: 5,
          "notice-info": _ctx.noticeInfo,
          "lang-cd": _ctx.languageCode,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closePopup(_ctx.LANDING_PAGE_POPUP.NOTICE)))
        }, null, 8, ["notice-info", "lang-cd"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.isOpenIdleTime && _ctx.userName)
      ? (_openBlock(), _createBlock(_component_IdleTimePopup, {
          key: 6,
          onClose: _ctx.onLogout,
          onConfirm: _cache[10] || (_cache[10] = ($event: any) => (_ctx.closePopup(_ctx.LANDING_PAGE_POPUP.IDLE_TIME)))
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}