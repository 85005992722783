
import { defineComponent, ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';

import { withLoading } from '@hems/component';
import WeeklyScheduler from '@hems/component/src/scheduler/WeeklyScheduler.vue';
import OperationSetting from '@hems/container/src/energyManagement/OperationSetting.vue';
import PresetSetting from '@hems/container/src/energyManagement/PresetSetting.vue';
import ScheduleDeleteList from '@hems/container/src/energyManagement/ScheduleDeleteList.vue';
import ScheduleSetting from '@hems/container/src/energyManagement/ScheduleSetting.vue';
import ScheduleStatusInfo from '@hems/container/src/energyManagement/ScheduleStatusInfo.vue';
import { useEnergyManagement, useDangerConfirmationModal } from '@hems/util';
import { ENERGY_POLICY } from '@hems/util/src/constant';
import type { EnergyManagementPageType } from '@hems/util/src/constant/energyManagement';
import { ENERGY_MANAGEMENT_PAGE_TYPE, ENERGY_MANAGEMENT_SETTING_TYPE } from '@hems/util/src/constant/energyManagement';
import { ENERGY_MANAGEMENT_ACTION, ENERGY_MANAGEMENT_MUTATION } from '@hems/util/src/stores/modules/energyManagement';

import type { CommonRootState } from 'hems/common/store';
import type { PresetInfoWithOperations, ScheduleInfo } from 'hems/energyManagement';

export default defineComponent({
  name: 'EnergyManagementContainer',
  components: {
    ScheduleSetting,
    WeeklyScheduler,
    PresetSetting,
    OperationSetting,
    ScheduleDeleteList,
    ScheduleStatusInfo,
  },
  props: {
    operationMode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const store = useStore<CommonRootState>();
    const { siteId, scheduleInfoList, weeklySchedulerEventList, updateWeeklySchedulerEventList } =
      useEnergyManagement();

    const currentPageType = ref<EnergyManagementPageType>(ENERGY_MANAGEMENT_PAGE_TYPE.SCHEDULE_SETTING);

    const selectedOperationMode = ref(props.operationMode);

    const resolveLeaveFunction = ref<((value: boolean) => void) | null>(null);

    const isTOU = computed(() => selectedOperationMode.value === ENERGY_POLICY.TIME_BASED);

    const isSchedulerDisabled = computed(() => !isTOU.value);

    const onChangeOperationMode = (operationModeValue: string) => {
      selectedOperationMode.value = operationModeValue;
    };

    const moveToPresetSetting = () => {
      store.commit(ENERGY_MANAGEMENT_MUTATION.UPDATE_WEEKLY_SCHEDULER_EVENT_LIST, []);
      currentPageType.value = ENERGY_MANAGEMENT_PAGE_TYPE.PRESET_SETTING;

      store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SELECTED_SCHEDULE_INFO, null);
    };

    const moveToScheduleSetting = () => {
      store.commit(ENERGY_MANAGEMENT_MUTATION.UPDATE_WEEKLY_SCHEDULER_EVENT_LIST, []);
      currentPageType.value = ENERGY_MANAGEMENT_PAGE_TYPE.SCHEDULE_SETTING;
    };

    const moveToScheduleDelete = () => {
      currentPageType.value = ENERGY_MANAGEMENT_PAGE_TYPE.SCHEDULE_DELETE;

      updateWeeklySchedulerEventList([]);
    };

    const onEditSchedule = (schedule: ScheduleInfo) => {
      moveToScheduleOperationSetting(schedule);
    };

    const onEditPreset = (preset: PresetInfoWithOperations | null) => {
      if (!preset) return;

      moveToPresetOperationSetting(preset);
    };

    const moveToScheduleOperationSetting = (schedule: ScheduleInfo) => {
      store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SELECTED_SCHEDULE_INFO, schedule);
      store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_SELECTED_OPERATION_INFO, {
        id: schedule.name,
        name: schedule.name,
        operations: schedule.operations,
      });
      store.dispatch(
        ENERGY_MANAGEMENT_ACTION.SET_CURRENT_ENERGY_MANAGEMENT_SETTING_TYPE,
        ENERGY_MANAGEMENT_SETTING_TYPE.SCHEDULE
      );
      currentPageType.value = ENERGY_MANAGEMENT_PAGE_TYPE.SCHEDULE_PRESET_OPERATION;
    };

    const moveToPresetOperationSetting = (preset: PresetInfoWithOperations) => {
      store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_SELECTED_OPERATION_INFO, {
        id: preset.presetId,
        name: preset.presetName,
        operations: preset.operations,
      });
      store.dispatch(
        ENERGY_MANAGEMENT_ACTION.SET_CURRENT_ENERGY_MANAGEMENT_SETTING_TYPE,
        ENERGY_MANAGEMENT_SETTING_TYPE.PRESET
      );
      currentPageType.value = ENERGY_MANAGEMENT_PAGE_TYPE.SCHEDULE_PRESET_OPERATION;
    };

    const onDeleteSchedule = (deletedScheduleNameList: string[]) => {
      const deletedScheduleInfoList = scheduleInfoList.value.filter(
        (scheduleInfo) => !deletedScheduleNameList.includes(scheduleInfo.name)
      );

      store.commit('energyManagement/setScheduleInfoList', deletedScheduleInfoList);
      updateWeeklySchedulerEventList([]);
      currentPageType.value = ENERGY_MANAGEMENT_PAGE_TYPE.SCHEDULE_SETTING;
    };

    const leaveModal = useDangerConfirmationModal({
      title: t('message.leave_without_saving_modal'),
      content: t('message.save_before_leave'),
      confirmButtonText: t('common.leave'),
      confirmCallback: () => {
        if (resolveLeaveFunction.value) {
          resolveLeaveFunction.value(true);
        }
      },
      cancelCallback: () => {
        if (resolveLeaveFunction.value) {
          resolveLeaveFunction.value(false);
        }
      },
    });

    watch(
      () => props.operationMode,
      () => {
        selectedOperationMode.value = props.operationMode;
      }
    );

    onMounted(() => {
      if (!siteId.value) return;

      withLoading(async () => {
        await Promise.all([
          store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_SCHEDULE_INFO_LIST_AND_DEFAULT_MODE, siteId.value),
          store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_PRESET_INFO_LIST_AND_OPERATION_MAP, siteId.value),
        ]);
      })();
    });

    onBeforeUnmount(() => {
      store.commit(ENERGY_MANAGEMENT_MUTATION.CLEAR_PRESET_SCHEDULE_INFO);
    });

    onBeforeRouteLeave(async (_to, _from, next) => {
      if (!isTOU.value) {
        next();

        return;
      }

      const isAllSaved = scheduleInfoList.value.every((schedule) => !schedule.isSaveRequired);
      if (isAllSaved) {
        next();

        return;
      }

      leaveModal.open();

      const leaveModalPromise = new Promise((resolve: (value: boolean) => void) => {
        resolveLeaveFunction.value = resolve;
      });

      const isLeaveConfirmed = await leaveModalPromise;
      if (isLeaveConfirmed) {
        next();
      } else {
        next(false);
      }
    });

    return {
      isSchedulerDisabled,
      selectedOperationMode,
      currentPageType,
      ENERGY_MANAGEMENT_PAGE_TYPE,
      scheduleInfoList,
      weeklySchedulerEventList,
      onChangeOperationMode,
      moveToPresetSetting,
      moveToScheduleDelete,
      moveToScheduleSetting,
      moveToPresetOperationSetting,
      onEditPreset,
      onDeleteSchedule,
      onEditSchedule,
    };
  },
});
