/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { Plugin } from 'vue';

import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant/provideInjection';

export type FacebookOAuth2Options = { appId: string; version?: string };

export type FacebookProfile = { email: string; name: string };

export type FacebookResponse = { status: string; authResponse: { accessToken: string } };

export class Vue3FacebookOAuth2 {
  private _email?: string;
  private _name?: string;
  constructor({ appId, version }: FacebookOAuth2Options) {
    window.fbAsyncInit = function () {
      FB.init({
        appId,
        cookie: true,
        xfbml: true,
        version: version ?? 'v12.0',
      });

      FB.AppEvents.logPageView();
    };

    // TODO: 타입지정 개선 확인 예정
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  public isLogin() {
    return new Promise((resolve) => {
      FB.getLoginStatus(async (res: FacebookResponse) => {
        if (res.status === 'connected') {
          resolve(true);

          return;
        }
        resolve(false);
      });
    });
  }

  public login() {
    return new Promise((resolve) => {
      FB.getLoginStatus(async (res: FacebookResponse) => {
        if (res.status === 'connected') {
          this._loadUserInfo().then(() => {
            resolve(true);
          });

          return;
        }
        FB.login(
          () => {
            this._loadUserInfo().then(() => {
              resolve(true);
            });
          },
          { scope: 'public_profile,email' }
        );
      });
    });
  }

  private _loadUserInfo() {
    const self = this;

    return new Promise((resolve) => {
      FB.api('/me', { fields: 'email,name' }, (profile: FacebookProfile) => {
        const { email, name } = profile;
        self._email = email;
        self._name = name;
        resolve(true);
      });
    });
  }

  public logout() {
    this._email = undefined;
    this._name = undefined;
    FB.logout();
  }

  public getEmail() {
    return this._email;
  }

  public getName() {
    return this._name;
  }

  public getAccessToken(): Promise<string> {
    return new Promise((resolve) => {
      FB.getLoginStatus(async (res: FacebookResponse) => {
        if (res.status === 'connected') {
          resolve(res.authResponse.accessToken);
        }
      });
    });
  }
}

const Vue3FacebookOAuth2Plugin: Plugin = {
  install: (app, options) => {
    const instance = new Vue3FacebookOAuth2(options);
    app.config.globalProperties.$facebookOAuth = instance;
    app.provide(PROVIDE_INJECT_KEYS.FACEBOOK_OAUTH, instance);
  },
};

export default Vue3FacebookOAuth2Plugin;
