/* eslint-disable no-param-reassign */
/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { Module, MutationPayload, Store } from 'vuex';

import type { CommonRootState } from 'hems/common/store';
import type { LoadingState } from 'hems/loading';

export const initState: LoadingState = {
  loadingCount: 0,
};

const prefix = 'loading';

export const LOADING_MUTATION = {
  INCREMENT_LOADING_COUNT: `${prefix}/incrementLoadingCount`,
  DECREMENT_LOADING_COUNT: `${prefix}/decrementLoadingCount`,
  RESET_LOADING: `${prefix}/resetLoading`,
} as const;

export const LOADING_ACTION = {
  START_LOADING: `${prefix}/startLoading`,
  STOP_LOADING: `${prefix}/stopLoading`,
  RESET_LOADING: `${prefix}/resetLoading`,
} as const;

export const loadingLogger = (store: Store<CommonRootState>) => {
  store.subscribe((mutation: MutationPayload, state: CommonRootState) => {
    if (mutation.type.startsWith('loading/')) {
      console.log(`[Loading state]:`, state.loading.loadingCount);
    }
  });
};

export default {
  namespaced: true,
  state: { ...initState },
  mutations: {
    incrementLoadingCount: (state) => {
      state.loadingCount = state.loadingCount + 1;
    },
    decrementLoadingCount: (state) => {
      if (state.loadingCount > 0) {
        state.loadingCount = state.loadingCount - 1;
      } else {
        state.loadingCount = 0;
      }
    },
    resetLoading(state) {
      state.loadingCount = 0;
    },
  },
  actions: {
    startLoading({ commit }) {
      commit('incrementLoadingCount');
    },
    stopLoading({ commit }) {
      commit('decrementLoadingCount');
    },
    resetLoading({ commit }) {
      commit('resetLoading');
    },
  },
  getters: {
    isLoading: (state) => {
      return state.loadingCount > 0;
    },
  },
} as Module<LoadingState, CommonRootState>;
