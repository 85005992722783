import type { ACDeviceTypeValue, ACSystemFieldTypeCodeValue } from '@hems/util/src/constant';
import { AC_DEVICE_TYPE, ACSystemModelNamePrefixes } from '@hems/util/src/constant';
import { AC_SYSTEM_FIELD_TYPE_CODE } from '@hems/util/src/constant/constant';

import type { SelectorOption } from 'hems';

import type { ValueType } from 'hems/common/utils';
import type { DeviceAC } from 'hems/device/siteinfo';

export const TIME_BASED_MODE_ACTION = {
  NOP: 'control.nop',
  AUTO: 'control.auto',
  MANUAL: 'control.manual',
  STOP_CHARGING: 'control.stop_charging',
  STOP_DISCHARGING: 'control.stop_discharging',
  // AC SYSTEM 용
  CHARGE_FROM_SOLAR: 'control.charge_from_solar',
  CHARGE_FROM_SOLAR_GRID: 'control.charge_from_solar_grid',
  MAXIMIZE_EXPORT: 'control.maximize_export',
  MAXIMIZE_SELF_CONSUMPTION: 'control.self_consumption',
  LIMIT_EXPORT: 'control.limit_export',
} as const;

export type TimeBasedModeActionType = ValueType<typeof TIME_BASED_MODE_ACTION>;

export const mowTimeBasedActions: SelectorOption[] = [
  {
    text: TIME_BASED_MODE_ACTION.NOP,
    value: '0',
  },
  {
    text: TIME_BASED_MODE_ACTION.AUTO,
    value: '1',
  },
  {
    text: TIME_BASED_MODE_ACTION.MANUAL,
    value: '2',
  },
  {
    text: TIME_BASED_MODE_ACTION.STOP_CHARGING,
    value: '3',
  },
  {
    text: TIME_BASED_MODE_ACTION.STOP_DISCHARGING,
    value: '4',
  },
];

export const acSystemTimeBasedActions: SelectorOption[] = [
  {
    text: TIME_BASED_MODE_ACTION.CHARGE_FROM_SOLAR,
    value: '5',
  },
  {
    text: TIME_BASED_MODE_ACTION.CHARGE_FROM_SOLAR_GRID,
    value: '6',
  },
  {
    text: TIME_BASED_MODE_ACTION.MAXIMIZE_EXPORT,
    value: '7',
  },
  {
    text: TIME_BASED_MODE_ACTION.MAXIMIZE_SELF_CONSUMPTION,
    value: '8',
  },
  {
    text: TIME_BASED_MODE_ACTION.LIMIT_EXPORT,
    value: '9',
  },
];

export const SETTING_TYPE = {
  BASIC: 'BS',
  ADVANCED: 'AS',
  TOU: 'TOU',
  BASIC_ADVANCED: 'BS_AS',
  ADVANCED_TOU: 'AS_TOU',
  BASIC_TOU: 'BS_TOU',
  BASIC_ADVANCED_TOU: 'BS_AS_TOU',
  ENGINEER: 'ES',
} as const;

export type SettingTypeValue = ValueType<typeof SETTING_TYPE>;

export const SETTING_TARGET = {
  SITE: 'site',
  AC_COMBINER: 'accb',
  AC_ESS: 'aces',
  SINGLE: 'single', // single로 내리는 경우 target을 보내지 않고 기존의 deviceId 기준 setting을 내림
  ADVANCED: 'advanced', // HEM-13219 AC System advanced setting 을 내릴때만 해당 target을 보냄
  INDIVIDUAL: 'individual',
} as const;

export type SettingTargetValue = ValueType<typeof SETTING_TARGET>;

const AC_DEVICE = {
  AC_COMBINER: 'AC Combiner',
  AC_COUPLED_ESS_HUB: 'AC Coupled ESS + HUB',
} as const;

export type ACDeviceValue = ValueType<typeof AC_DEVICE>;

export const SETTING_TAB_CODE = {
  SITE: 'site',
  DEVICE: 'device',
} as const;

export type SettingTabValue = ValueType<typeof SETTING_TAB_CODE>;

export const multiSettingTabName: ReadonlyArray<SelectorOption<SettingTabValue>> = [
  { text: 'SITE', value: SETTING_TAB_CODE.SITE },
  { text: 'DEVICE', value: SETTING_TAB_CODE.DEVICE },
] as const;

interface DeviceTabList {
  basicDeviceOption: SelectorOption[];
  engineeringDeviceOption: SelectorOption<string>[];
  controlDeviceOption: SelectorOption<string>[];
}

type IndividualOptionKey = `AC Coupled ESS (serial #${string})`;
type IndividualOptionValue = `aces_individual_${string}`;

type TabOption = {
  text: ACDeviceValue | IndividualOptionKey;
  value: ACSystemFieldTypeCodeValue;
};

let basicDeviceOption: SelectorOption[] = [];
let engineeringDeviceOption: SelectorOption<string>[] = [];
let controlDeviceOption: SelectorOption<string>[] = [];

const combinerOption: TabOption = { text: AC_DEVICE.AC_COMBINER, value: AC_SYSTEM_FIELD_TYPE_CODE.ACCB };
const ESSHubOption: TabOption = { text: AC_DEVICE.AC_COUPLED_ESS_HUB, value: AC_SYSTEM_FIELD_TYPE_CODE.ACES };

const clearOptions = () => {
  basicDeviceOption = [];
  engineeringDeviceOption = [];
  controlDeviceOption = [];
};

const isACCoupledESS = (productModelName: string) => productModelName.startsWith(ACSystemModelNamePrefixes[1]);

const setIndividualOption = (deviceList: DeviceAC[]) => {
  deviceList.forEach((device) => {
    if (isACCoupledESS(device.deviceInfo.product_model_nm)) {
      const individualOption: { text: IndividualOptionKey; value: IndividualOptionValue } = {
        text: `AC Coupled ESS (serial #${device.deviceInfo.device_id})`,
        value: `aces_individual_${device.deviceInfo.device_id}`,
      };

      basicDeviceOption.push(individualOption);
      engineeringDeviceOption.push(individualOption);
      controlDeviceOption.push(individualOption);
    }
  });
};

const setCase1Option = () => {
  basicDeviceOption.push(combinerOption);
  engineeringDeviceOption.push(combinerOption);
  controlDeviceOption.push(combinerOption);
};

const setCase3Option = (deviceList: DeviceAC[]) => {
  controlDeviceOption.push(combinerOption);
  basicDeviceOption.push(combinerOption);
  engineeringDeviceOption.push(combinerOption);

  basicDeviceOption.push(ESSHubOption);
  engineeringDeviceOption.push(ESSHubOption);

  setIndividualOption(deviceList);
};

const setCase4Option = (deviceList: DeviceAC[]) => {
  basicDeviceOption.push(ESSHubOption);
  engineeringDeviceOption.push(ESSHubOption);

  setIndividualOption(deviceList);
};

const setCase5Option = (deviceList: DeviceAC[]) => {
  setIndividualOption(deviceList);
};

const setCase6Option = (deviceList: DeviceAC[]) => {
  controlDeviceOption.push(combinerOption);
  engineeringDeviceOption.push(combinerOption);

  setIndividualOption(deviceList);
};

export const getDeviceTabListForAC = (acDeviceType: ACDeviceTypeValue, deviceList: DeviceAC[]): DeviceTabList => {
  const setDeviceTabList: Record<ACDeviceTypeValue, (() => void) | null> = {
    [AC_DEVICE_TYPE.AC_COMBINER]: () => setCase1Option(),
    [AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB]: () => setCase3Option(deviceList),
    [AC_DEVICE_TYPE.AC_THIRD_PARTY_MULTI_ESS_HUB]: () => setCase4Option(deviceList),
    [AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS_HUB]: () => setCase4Option(deviceList),
    [AC_DEVICE_TYPE.AC_ESS]: null,
    [AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS]: () => setCase6Option(deviceList),
    [AC_DEVICE_TYPE.AC_MULTI_ESS]: null,
    [AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS]: () => setCase5Option(deviceList),
  };

  const setDeviceTabListAction = setDeviceTabList[acDeviceType];

  clearOptions();
  setDeviceTabListAction?.();

  return { basicDeviceOption, engineeringDeviceOption, controlDeviceOption };
};

export const PCS_CONNECTION_MODE = {
  INTERNAL: 0,
  EXTERNAL: 1,
  NONE: 2,
} as const;

export const EXTERNAL_EMS_CONNECTION = {
  DISABLE: 0,
  CAN: 1,
  MODBUS_TCP: 2,
  MODBUS_RTU: 3,
} as const;

export const EXTERNAL_EMS_MODEL = {
  NONE: 0,
  FOXESS_H1: 1,
  FOXESS_H3: 2,
  FOXESS_H3_PRO: 3,
  FOXESS_H3_G2: 4,
  CUSTOM: 99,
} as const;

export const MPU_AVOIDANCE = {
  NONE: 0,
  BACKFEED_LIMIT: 1,
  // BBOC: 2 // TODO: 추후 BBOC 추가 시 주석 해제
} as const;

export const NEC_120_RULE_RATE = 1.2 as const;

export const BACKUP_TYPE = {
  NONE: 0,
  WHOLE_HOME_BACKUP: 1,
  PARTIAL_HOME_BACKUP: 2,
} as const;

export type BackupTypeValue = ValueType<typeof BACKUP_TYPE>;
