import { computed } from 'vue';
import { useStore } from 'vuex';
import { LOADING_ACTION } from '@hems/util/src/stores/modules/loading';
// eslint-disable-next-line import/prefer-default-export
export const useLoading = () => {
    const store = useStore();
    const isLoading = computed(() => store.getters['loading/isLoading']);
    const startLoading = () => {
        store.dispatch(LOADING_ACTION.START_LOADING);
    };
    const stopLoading = () => {
        store.dispatch(LOADING_ACTION.STOP_LOADING);
    };
    const resetLoading = () => {
        store.dispatch(LOADING_ACTION.RESET_LOADING);
    };
    const withLoading = (apiFunc, close = true) => async (...args) => {
        startLoading();
        try {
            await apiFunc(...args);
        }
        catch (e) {
            if (close) {
                stopLoading();
            }
            throw e;
        }
        if (close) {
            stopLoading();
        }
    };
    return {
        isLoading,
        startLoading,
        stopLoading,
        resetLoading,
        withLoading,
    };
};
