import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class HomeApplianceService extends Service {
    constructor(axios) {
        super(axios);
    }
    getApplianceList = async (siteId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/smart-things/${siteId}`));
        return data;
    };
    getApplianceStatus = async (deviceId) => {
        return await this.get(`/devices/${deviceId}/status`);
    };
    getApplianceInfo = async (deviceId) => {
        return await this.get(`/devices/${deviceId}`);
    };
    getApplianceHealth = async (deviceId) => {
        return await this.get(`/devices/${deviceId}/health`);
    };
    getApplianceLocations = async () => {
        const { items } = await this.get('/locations');
        return items;
    };
    getApplianceRooms = async (locationId) => {
        const { items } = await this.get(`/locations/${locationId}/rooms`);
        return items;
    };
    controlAppliance = async (deviceId, params) => {
        return await this.post(`/devices/${deviceId}/commands`, params);
    };
    refreshSmartThingsToken = async (token) => {
        const { data } = await this.post(apiWrapper.managerApi('/smart-things/tokens/refresh'), { token });
        return data;
    };
    getApplianceChart = async (siteId, params) => {
        const { data } = await this.get(apiWrapper.managerApi(`/smart-things/charts/${siteId}`), params);
        return data;
    };
    getChartByAppliance = async (applianceDeivceId, params) => {
        const { data } = await this.get(apiWrapper.managerApi(`/smart-things/charts/${applianceDeivceId}/powers`), params);
        return data;
    };
}
