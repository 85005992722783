
import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';

import PrivacyPolicyPopup from '@hems/container/src/terms/PrivacyPolicyPopup.vue';
import { useRole } from '@hems/util';

import GeneralAccountInfoPopup from '@/components/popup/accountinfo/general/GeneralAccountInfoPopup.vue';
import InstallerAccountInfoPopup from '@/components/popup/accountinfo/installer/InstallerAccountInfoPopup.vue';
import type { RootState } from '@/store';

import type { Role } from 'hems';

export default defineComponent({
  name: 'AccountInfoPopup',
  components: {
    GeneralAccountInfoPopup,
    InstallerAccountInfoPopup,
    PrivacyPolicyPopup,
  },
  emits: ['close', 'withdrawal'],
  setup() {
    const store = useStore<RootState>();
    const userInfo = store.state.user;

    const { isUser } = useRole();
    const state = reactive({
      roleNm: computed<Role | null>(() => userInfo.roleNm),
      privacyPopup: false,
    });

    function openPrivacyPopup() {
      state.privacyPopup = true;
    }
    function closePrivacyPopup() {
      state.privacyPopup = false;
    }

    return {
      state,
      openPrivacyPopup,
      closePrivacyPopup,
      isUser,
    };
  },
});
