/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import forEach from 'lodash-es/forEach';
import isNil from 'lodash-es/isNil';
import { DateHelper } from '@hems/util';
import { GRAPH_GROUP_CODE, GRAPH_TERM_UNIT, GRID_STATISTICS, UNIT } from '@hems/util/src/constant';
import { calculatePowerFactor } from '@hems/util/src/helper/dashboardHelper';
import { getConvertedGraphDateString } from '@hems/util/src/helper/dateHelper';
import { convertCelsiusToFahrenheit } from '@hems/util/src/helper/numberformatHelper';
import { getGraphGroupCodeTypeValue, getGraphGroupCodeListTypeValue } from '@hems/util/src/helper/tsguardHelper';
export const clearChartData = (chartData) => {
    forEach(chartData, (_value, key) => {
        const chartDataKey = getGraphGroupCodeTypeValue(key);
        if (chartDataKey) {
            chartData[chartDataKey]?.forEach((item) => (item.data = []));
        }
    });
};
export const getChartTemplateAndData = (chartConfig, t) => {
    const chartDataTemplate = {};
    const chartData = chartConfig.reduce((prev, curr) => {
        chartDataTemplate[curr.filterGroupCode] = curr;
        return {
            ...prev,
            [curr.filterGroupCode]: curr.filterList.map((item) => {
                return {
                    id: item.code,
                    name: `${t(item.name)}${item.postfix ? ` (${item.postfix})` : ''}${item.unit ? ` [${item.unit}]` : ''}`,
                    yAxis: item.yAxis,
                    data: [],
                    hidden: item.hidden,
                };
            }),
        };
    }, {});
    return {
        chartDataTemplate,
        chartData,
    };
};
export const getXAxisCategories = (data, termUnit = GRAPH_TERM_UNIT.MINUTE) => {
    return data.map((item) => DateHelper.getConvertedGraphDateString(item.stdDt, termUnit));
};
export const updateChartData = (chartData, statisticsDataList, graphKeys) => {
    clearChartData(chartData);
    const groupKeys = getGraphGroupCodeListTypeValue(Object.keys(graphKeys));
    statisticsDataList.forEach((_data, index) => {
        groupKeys?.forEach((groupKey) => {
            graphKeys[groupKey]?.forEach((key) => {
                const graphData = statisticsDataList[index];
                const graphDataByKey = graphData[key] ?? null;
                chartData[groupKey]?.find((item) => item.id === key)?.data.push(graphDataByKey);
            });
        });
    });
};
export const getColumns = (config, t) => {
    const columnArr = [{ header: '', name: 'stdDt' }];
    config.forEach((item) => {
        item.filterList.forEach((item) => {
            columnArr.push({
                header: `${t(`${item.name}`)}${item.postfix ? ` (${item.postfix})` : ''}`,
                name: item.code,
            });
        });
    });
    return columnArr;
};
export const convertFormatDate = (data) => {
    return data.map((item) => ({
        ...item,
        stdDt: getConvertedGraphDateString(item.stdDt, GRAPH_TERM_UNIT.HOUR),
    }));
};
export const getGraphDataByGroupCodes = (groupCodes, chartData) => {
    return groupCodes.reduce((accumulator, groupCode) => [...accumulator, ...chartData[groupCode]], []);
};
export const getGraphDataByGroupCodesACSystem = (groupCodes, chartData) => {
    if (groupCodes.includes(GRAPH_GROUP_CODE.BATTERY)) {
        chartData[GRAPH_GROUP_CODE.BATTERY] = getConvertedBatteryData(chartData[GRAPH_GROUP_CODE.BATTERY]);
    }
    if (groupCodes.includes(GRAPH_GROUP_CODE.GRID)) {
        chartData[GRAPH_GROUP_CODE.GRID] = getACMeterData(chartData[GRAPH_GROUP_CODE.GRID]);
    }
    return getGraphDataByGroupCodes(groupCodes, chartData);
};
const calculateGraphPowerFactor = (meterActivePowerList, meterReactivePowerList) => {
    return meterActivePowerList.map((meterActivePower, index) => {
        const meterReactivePower = meterReactivePowerList[index];
        return calculatePowerFactor(meterActivePower ?? 0, meterReactivePower ?? 0);
    });
};
export const getACMeterData = (meterDataList) => {
    const { meterActivePowerList, meterReactivePowerList } = meterDataList.reduce((acc, meterData) => {
        if (meterData.id === GRID_STATISTICS.ACTIVE_POWER) {
            acc.meterActivePowerList = meterData.data;
        }
        else if (meterData.id === GRID_STATISTICS.REACTIVE_POWER) {
            acc.meterReactivePowerList = meterData.data;
        }
        return acc;
    }, {
        meterActivePowerList: [],
        meterReactivePowerList: [],
    });
    return meterDataList.map((meterData) => {
        if (meterData.id === GRID_STATISTICS.POWER_FACTOR) {
            return { ...meterData, data: calculateGraphPowerFactor(meterActivePowerList, meterReactivePowerList) };
        }
        return meterData;
    });
};
export const getConvertedBatteryData = (batteryDataList) => batteryDataList.map((batteryData) => {
    if (batteryData.yAxis === UNIT.FAHRENHEIT) {
        return {
            ...batteryData,
            data: batteryData.data.map((data) => (!isNil(data) ? convertCelsiusToFahrenheit(data) : null)),
        };
    }
    return batteryData;
});
