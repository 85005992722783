import { defineComponent } from 'vue';
import { useSiteMasterDeviceInfo } from '@hems/util';
export default defineComponent({
    name: 'GridInfo',
    props: {
        formattedData: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { isConsumptionCT } = useSiteMasterDeviceInfo();
        return {
            isConsumptionCT,
        };
    },
});
