import { defineComponent, computed, onBeforeUnmount, onMounted, watch, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import EnergyFlowAC from '@hems/container/src/siteEnergy/flow/EnergyFlowAC.vue';
import EnergyFlowACHomeLayer from '@hems/container/src/siteEnergy/flow/EnergyFlowACHomeLayer.vue';
import EnergyFlowACPipeLayer from '@hems/container/src/siteEnergy/flow/EnergyFlowACPipeLayer.vue';
import EnergyFlowACProductLayer from '@hems/container/src/siteEnergy/flow/EnergyFlowACProductLayer.vue';
import { defaultEnergyFlow, getEnergyFlowCases, getEnergyFlowFormattedData, } from '@hems/container/src/siteEnergy/flow/EnergyFlowFunctions';
import GridInfo from '@hems/container/src/siteEnergy/flow/GridInfo.vue';
import NetworkStatus from '@hems/container/src/siteEnergy/flow/NetworkStatus.vue';
import OperationModeInfo from '@hems/container/src/siteEnergy/flow/OperationModeInfo.vue';
import ProductionInfo from '@hems/container/src/siteEnergy/flow/ProductionInfo.vue';
import { useGenerator, useLocale, useSiteConfiguration, useSiteMasterDeviceInfo } from '@hems/util';
import { PROVIDE_INJECT_KEYS, NETWORK_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'EnergyFlowACContainer',
    components: {
        EnergyFlowACHomeLayer,
        EnergyFlowACPipeLayer,
        EnergyFlowAC,
        EnergyFlowACProductLayer,
        NetworkStatus,
        ProductionInfo,
        OperationModeInfo,
        GridInfo,
    },
    props: {
        realtimeData: {
            type: Object,
            required: true,
        },
        isRealtimeConnected: {
            type: Boolean,
            required: false,
            default: false,
        },
        disconnect: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const popup = inject(PROVIDE_INJECT_KEYS.CONTROL_POPUP);
        const { intlFormatLocale } = useLocale();
        const { isGenerator } = useGenerator();
        const { hasPV, hasESS } = useSiteConfiguration();
        const { isConsumptionCT } = useSiteMasterDeviceInfo();
        const energyFlow = ref(defaultEnergyFlow);
        const formattedData = ref(getEnergyFlowFormattedData(props.realtimeData, t));
        const isOffGrid = computed(() => !(props.realtimeData.gridStatus ?? true));
        const isGeneratorPower = computed(() => (props.realtimeData.generatorPower ?? 0) > 0);
        const showGenerator = computed(() => isOffGrid.value && isGenerator && isGeneratorPower.value);
        const showFlow = (data) => {
            energyFlow.value = getEnergyFlowCases(data, showGenerator.value);
        };
        const clearEnergyFlow = () => {
            energyFlow.value = defaultEnergyFlow;
        };
        watch(() => props.realtimeData, () => {
            showFlow(props.realtimeData);
        });
        watch(() => props.disconnect, () => {
            if (props.disconnect) {
                clearEnergyFlow();
            }
        });
        watch(() => [intlFormatLocale.value, props.realtimeData], () => {
            formattedData.value = getEnergyFlowFormattedData(props.realtimeData, t);
        });
        watch(() => props.isRealtimeConnected, clearEnergyFlow);
        onMounted(() => {
            showFlow(props.realtimeData);
        });
        onBeforeUnmount(() => {
            clearEnergyFlow();
        });
        return {
            popup,
            energyFlow,
            formattedData,
            NETWORK_TYPE,
            showGenerator,
            hasPV,
            hasESS,
            isConsumptionCT,
        };
    },
});
