import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30c5cd43"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "pw_validation_container" };
const _hoisted_2 = { class: "pw_validation" };
const _hoisted_3 = { class: "terms-and-conditions" };
const _hoisted_4 = { class: "agree" };
const _hoisted_5 = { class: "agree" };
const _hoisted_6 = { class: "is_default" };
const _hoisted_7 = { class: "is_edit" };
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
    type: "submit",
    class: "btn_m btn_c_3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_BasicInput = _resolveComponent("BasicInput");
    const _component_SettingTableRow = _resolveComponent("SettingTableRow");
    const _component_TruncateInput = _resolveComponent("TruncateInput");
    const _component_Selector = _resolveComponent("Selector");
    const _component_LabelInput = _resolveComponent("LabelInput");
    const _component_RadioGroup = _resolveComponent("RadioGroup");
    const _component_SettingTable = _resolveComponent("SettingTable");
    const _component_Tooltip = _resolveComponent("Tooltip");
    const _component_Image2 = _resolveComponent("Image2");
    const _component_BasicCheckbox = _resolveComponent("BasicCheckbox");
    const _component_Form = _resolveComponent("Form");
    return (_openBlock(), _createBlock(_component_Form, {
        class: _normalizeClass({ 'account-info-renewal': !_ctx.isUserApp }),
        "validation-schema": _ctx.schema,
        onSubmit: _ctx.onSave,
        onKeydown: _withKeys(_ctx.preventKeyEnter, ["enter"])
    }, {
        default: _withCtx(({ handleReset }) => [
            _createVNode(_component_SettingTable, null, {
                default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('common.site_id')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                                modelValue: _ctx.state.data.site_id,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.state.data.site_id) = $event)),
                                name: "site_id",
                                type: _ctx.INPUT_TYPE.NUMBER,
                                readonly: ""
                            }, null, 8, ["modelValue", "type"])
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('account.id')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_TruncateInput, {
                                modelValue: _ctx.state.data.user_id,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.state.data.user_id) = $event)),
                                class: "ok",
                                name: "user_id",
                                readonly: true,
                                "tooltip-text": _ctx.state.data.user_id || '',
                                "tooltip-position": ['bottom']
                            }, null, 8, ["modelValue", "tooltip-text"])
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('account.name')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                                modelValue: _ctx.state.data.user_nm,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.state.data.user_nm) = $event)),
                                name: "user_nm",
                                readonly: !_ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('account.email')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                                modelValue: _ctx.state.data.email,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.state.data.email) = $event)),
                                name: "email",
                                readonly: !_ctx.state.editable,
                                "validate-mode": "blur"
                            }, null, 8, ["modelValue", "readonly"])
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('account.telephone'),
                        "is-multi-values": ""
                    }, {
                        default: _withCtx(() => [
                            (!_ctx.state.editable)
                                ? (_openBlock(), _createBlock(_component_BasicInput, {
                                    key: 0,
                                    modelValue: _ctx.telNum,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.telNum) = $event)),
                                    name: "telephone",
                                    readonly: ""
                                }, null, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                            (_ctx.state.editable)
                                ? (_openBlock(), _createBlock(_component_Selector, {
                                    key: 1,
                                    modelValue: _ctx.state.data.local_mpn_no,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.state.data.local_mpn_no) = $event)),
                                    label: "Region",
                                    name: "local_mpn_no",
                                    readonly: !_ctx.state.editable,
                                    options: _ctx.dialSelectOptions,
                                    "selected-display-type": "value",
                                    "is-display-selected-in-list": false
                                }, null, 8, ["modelValue", "readonly", "options"]))
                                : _createCommentVNode("", true),
                            (_ctx.state.editable)
                                ? (_openBlock(), _createBlock(_component_LabelInput, {
                                    key: 2,
                                    modelValue: _ctx.state.data.mpn_no,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.state.data.mpn_no) = $event)),
                                    label: _ctx.$t('account.telephone'),
                                    name: "mpn_no",
                                    readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "label", "readonly"]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('account.incident_email')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_RadioGroup, {
                                modelValue: _ctx.state.data.alarm_mail_recv_flag,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((_ctx.state.data.alarm_mail_recv_flag) = $event)),
                                name: "alarm_mail_recv_flag",
                                readonly: !_ctx.state.editable,
                                options: [
                                    {
                                        text: _ctx.$t('common.receive'),
                                        value: 'Y',
                                    },
                                    {
                                        text: _ctx.$t('common.reject'),
                                        value: 'N',
                                    },
                                ]
                            }, null, 8, ["modelValue", "readonly", "options"])
                        ]),
                        _: 1
                    }, 8, ["title"])
                ]),
                _: 1
            }),
            _createVNode(_component_SettingTable, {
                "table-title": _ctx.$t('account.change_password')
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('account.new_pw')
                    }, {
                        tooltip: _withCtx(() => [
                            _createVNode(_component_Tooltip, {
                                "tooltip-position": ['bottom'],
                                "tooltip-text": _ctx.$t('message.pw_use_smartthings'),
                                "image-base-url": "svg",
                                "image-name": "warning_circle",
                                "image-extension": "svg",
                                style: { "display": "inline-block" }
                            }, null, 8, ["tooltip-text"])
                        ]),
                        default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                                modelValue: _ctx.state.data.password,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.state.data.password) = $event)),
                                name: "password",
                                type: _ctx.INPUT_TYPE.PASSWORD,
                                readonly: !_ctx.state.editable,
                                placeholder: "********",
                                autocomplete: "off"
                            }, null, 8, ["modelValue", "type", "readonly"])
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", null, [
                                _createVNode(_component_Image2, {
                                    class: _normalizeClass(_ctx.stateValidationCheck?.checkValidation1 ? 'checked_t' : 'checked_f'),
                                    name: "pwValidationCheck1",
                                    src: "svg/pw_check",
                                    extension: "svg"
                                }, null, 8, ["class"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.enter_8_20')), 1)
                            ]),
                            _createElementVNode("div", null, [
                                _createVNode(_component_Image2, {
                                    class: _normalizeClass(_ctx.stateValidationCheck?.checkValidation2 ? 'checked_t' : 'checked_f'),
                                    name: "pwValidationCheck2",
                                    src: "svg/pw_check",
                                    extension: "svg"
                                }, null, 8, ["class"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.one_number_letter')), 1)
                            ]),
                            _createElementVNode("div", null, [
                                _createVNode(_component_Image2, {
                                    class: _normalizeClass(_ctx.stateValidationCheck?.checkValidation3 ? 'checked_t' : 'checked_f'),
                                    name: "pwValidationCheck3",
                                    src: "svg/pw_check",
                                    extension: "svg"
                                }, null, 8, ["class"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.one_special_character')), 1)
                            ])
                        ])
                    ]),
                    _createVNode(_component_SettingTableRow, {
                        title: _ctx.$t('account.confirm_new_pw')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                                modelValue: _ctx.state.data.password_confirm,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((_ctx.state.data.password_confirm) = $event)),
                                name: "password_confirm",
                                type: _ctx.INPUT_TYPE.PASSWORD,
                                readonly: !_ctx.state.editable,
                                placeholder: "********",
                                autocomplete: "off"
                            }, null, 8, ["modelValue", "type", "readonly"])
                        ]),
                        _: 1
                    }, 8, ["title"])
                ]),
                _: 1
            }, 8, ["table-title"]),
            (['AU'].includes(_ctx.store.state.appCtx.envLocale))
                ? (_openBlock(), _createBlock(_component_SettingTable, {
                    key: 0,
                    style: { "text-align": "left" }
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("a", {
                                href: "javascript:void(0)",
                                onClick: _cache[10] || (_cache[10] = ($event) => (_ctx.$emit('openPrivacyPopup')))
                            }, _toDisplayString(_ctx.$t('common.pp_terms_conditions')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_BasicCheckbox, {
                                modelValue: _ctx.state.data.usage_report,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((_ctx.state.data.usage_report) = $event)),
                                name: "usage_report",
                                readonly: !_ctx.state.editable
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('message.individual_usage_optional')), 1)
                                ]),
                                _: 1
                            }, 8, ["modelValue", "readonly"])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_BasicCheckbox, {
                                modelValue: _ctx.state.data.direct_marketing,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event) => ((_ctx.state.data.direct_marketing) = $event)),
                                name: "direct_marketing",
                                readonly: !_ctx.state.editable
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('message.direct_marketing_optional')), 1)
                                ]),
                                _: 1
                            }, 8, ["modelValue", "readonly"])
                        ])
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true),
            _createElementVNode("div", {
                class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.state.editable }])
            }, [
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("button", {
                        type: "button",
                        class: "btn_m btn_c_2",
                        onClick: _cache[13] || (_cache[13] = ($event) => (_ctx.$emit('withdrawal')))
                    }, _toDisplayString(_ctx.$t('account.termination')), 1),
                    _createElementVNode("button", {
                        type: "button",
                        class: "btn_m btn_c_3",
                        onClick: _cache[14] || (_cache[14] =
                            //@ts-ignore
                            (...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                    }, _toDisplayString(_ctx.$t('common.modify')), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("button", {
                        type: "button",
                        class: "btn_m btn_c_2",
                        onClick: () => {
                            _ctx.onCancel();
                            handleReset();
                        }
                    }, _toDisplayString(_ctx.$t('common.cancel')), 9, _hoisted_8),
                    _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('common.ok')), 1)
                ])
            ], 2)
        ]),
        _: 1
    }, 8, ["class", "validation-schema", "onSubmit", "onKeydown"]));
}
