import { Helper } from '@hems/util';
import { InstallerAccountService } from './InstallerAccountService';
import { apiWrapper } from '../../util/helper';
export default class InstallerAccountServiceAdmin extends InstallerAccountService {
    constructor(axios) {
        super(axios);
    }
    async getInstallerAccountList(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/installers'), params);
        return data;
    }
    async getInstallerAccountDetails(installerId) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/installer-profiles'), { userId: installerId });
        return data;
    }
    async putInstallerAccountDetails(installerId, params) {
        const _params = { ...params, installer_id: installerId };
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/installers/profiles'), Helper.keyToCamelCase(_params));
        return data;
    }
    async deleteInstallerAccountDetails(installerId) {
        const { data } = await this.delete(apiWrapper.managerApi('/managements/users/profiles'), undefined, { data: { userId: installerId } });
        return data;
    }
}
