
import { defineComponent, onMounted, reactive, ref } from 'vue';

import { PopupWrapper, BasicInput } from '@hems/component';
import { INPUT_TYPE } from '@hems/util/src/constant/input';

import { Image, GoogleLoginButton } from '@/components';
export default defineComponent({
  name: 'LoginPopup',
  components: {
    PopupWrapper,
    BasicInput,
    Image,
    GoogleLoginButton,
  },
  emits: ['close', 'login', 'findIdPw', 'join', 'googleLogin', 'facebookLogin', 'appleLogin'],
  setup(_, { emit }) {
    const usernameEl = ref(null as null | HTMLInputElement);
    const state = reactive({
      username: null,
      password: null,
    });
    const closePopup = (e: Event) => {
      e.preventDefault();
      emit('close');
    };
    function onLogin(e: Event) {
      e.preventDefault();
      const { username, password } = state;
      emit('login', { username, password });
    }

    onMounted(() => {
      usernameEl.value?.focus();
    });

    return {
      state,
      usernameEl,
      closePopup,
      onLogin,
      INPUT_TYPE,
    };
  },
});
