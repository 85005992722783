import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-adabf432"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "btn_wrap_m mt50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "ty_6",
    style: {"opacity":"1","left":"50%","margin-left":"-360px","top":"185px"}
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.timeout', { min: 60 })), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "btn_xs btn_c_2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.$t('account.logout')), 1),
        _createElementVNode("button", {
          type: "button",
          class: "btn_xs btn_c_3",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, _toDisplayString(_ctx.$t('common.continue')), 1)
      ])
    ]),
    _: 3
  }))
}