import CommonService from '@hems/service/src/api/common/CommonService';
import type { ACDeviceTypeValue } from '@hems/util/src/constant';
import {
  ACSystemModelNamePrefixes,
  AC_DEVICE_TYPE,
  CONSUMPTION_CT_TYPE,
  EMS_PRIORITY_ORDER_MAP,
  GEN_TYPE,
} from '@hems/util/src/constant';

import type { DeviceProfileACSystem } from 'hems/device/dashboard/smartmodule/acsys';
import type {
  DeviceInfo,
  PvModuleGen2,
  PvModuleGen3,
  PvModuleFox,
  PvModule,
  SmartModuleGen2,
  SmartModuleACSystem,
} from 'hems/device/deviceinfo';
import type { Device, DeviceProfileAllGenType } from 'hems/device/siteinfo';
import type { SiteConfiguration } from 'hems/install';

export function isPvModule(data: Partial<DeviceInfo>): data is Partial<PvModule> {
  if (isPvModuleGen2(data) || isPvModuleGen3(data) || isPvModuleFox(data)) return true;

  return false;
}

export function isPvModuleGen2(data: Partial<DeviceInfo>): data is PvModuleGen2 {
  if (data.gen_type === 'GEN2') return true;

  return false;
}
export function isPvModuleGen3(data: Partial<DeviceInfo>): data is PvModuleGen3 {
  if (data.gen_type === 'GEN3') return true;

  return false;
}
export function isPvModuleFox(data: Partial<DeviceInfo>): data is PvModuleFox {
  if (data.gen_type === 'FOX_ESS' || data.gen_type === 'FOX_ESS_H3') return true;

  return false;
}
export function isSmartModuleGen2(data: Partial<DeviceInfo>): data is Partial<SmartModuleGen2> {
  if (data.gen_type?.startsWith('DC_OPTIMIZER_GEN2')) return true;

  return false;
}
export function isSmartModuleACSystem(data: Partial<DeviceInfo>): data is Partial<SmartModuleACSystem> {
  if (data.gen_type?.startsWith('AC_SYS')) return true;

  return false;
}

export const getACSystemType = (
  deviceInfo: Partial<DeviceProfileACSystem>[],
  siteConfiguration: SiteConfiguration
): ACDeviceTypeValue => {
  if (!siteConfiguration) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return deviceInfo[0].device_type_cd!;
  }

  const { hasESS, hasHub, hasThirdParty } = siteConfiguration;

  if (hasHub) {
    if (hasThirdParty) {
      // case 4
      const ACESSList = deviceInfo.filter((item) => item.device_type_cd === AC_DEVICE_TYPE.AC_ESS);

      return ACESSList.length >= 2
        ? AC_DEVICE_TYPE.AC_THIRD_PARTY_MULTI_ESS_HUB
        : AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS_HUB;
    }

    // case 3
    return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB;
  }

  if (hasThirdParty) {
    // case 5
    return AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS;
  }

  if (hasESS) {
    const ACCombiner = deviceInfo.find((item) => item.device_type_cd === AC_DEVICE_TYPE.AC_COMBINER);
    if (ACCombiner) {
      // case 6
      return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS;
    }

    // case 8
    const ACESSList = deviceInfo.filter((item) => item.device_type_cd === AC_DEVICE_TYPE.AC_ESS);

    return ACESSList.length >= 2 ? AC_DEVICE_TYPE.AC_MULTI_ESS : AC_DEVICE_TYPE.AC_ESS;
  }

  // case 1
  return AC_DEVICE_TYPE.AC_COMBINER;
};

export const isACSystemModel = (productModelName: string) =>
  ACSystemModelNamePrefixes.some((modelNamePrefix) => productModelName.startsWith(modelNamePrefix));

export const deviceInfoSorter = (deviceA: Device, deviceB: Device): number => {
  if (!deviceA.deviceInfo.ems_type_cd || !deviceB.deviceInfo.ems_type_cd) return 0;

  const priorityDeviceA = EMS_PRIORITY_ORDER_MAP[deviceA.deviceInfo.ems_type_cd] ?? Number.MAX_SAFE_INTEGER;
  const priorityDeviceB = EMS_PRIORITY_ORDER_MAP[deviceB.deviceInfo.ems_type_cd] ?? Number.MAX_SAFE_INTEGER;

  return priorityDeviceA - priorityDeviceB;
};

export const checkConnectionStatus = async (deviceInfo: DeviceProfileACSystem | null) => {
  const commonService = new CommonService(window.axiosInstance.axios);
  if (!deviceInfo) return false;

  try {
    return await commonService.isConnection(deviceInfo.device_id);
  } catch (e) {
    console.error(e);

    return false;
  }
};
