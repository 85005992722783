import { defineComponent } from 'vue';
export default defineComponent({
    name: 'TitlePanel',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    emits: ['clickShowMore'],
});
