import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fd7cafe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "left-align-content" }
const _hoisted_3 = { class: "tb_total ty_2" }
const _hoisted_4 = { class: "total-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListSize = _resolveComponent("ListSize")!
  const _component_Pagenation = _resolveComponent("Pagenation")!

  return (_ctx.listTotalCount !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ListSize, {
            modelValue: _ctx.pageSize,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageSize) = $event)),
            onChange: _ctx.onChangeListSize
          }, null, 8, ["modelValue", "onChange"]),
          _createElementVNode("p", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.fleet_management.of')) + " ", 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatNumber(_ctx.listTotalCount)), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.fleet_management.entries')), 1)
          ])
        ]),
        _createVNode(_component_Pagenation, {
          modelValue: _ctx.pageNumber,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageNumber) = $event)),
            _ctx.onChangePage
          ],
          "row-count-per-page": Number(_ctx.pageSize),
          "total-row-count": _ctx.listTotalCount
        }, null, 8, ["modelValue", "row-count-per-page", "total-row-count", "onUpdate:modelValue"])
      ]))
    : _createCommentVNode("", true)
}