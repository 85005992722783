export const ACSystemHistoryGraphData = {
    chartData: [
        {
            colecDt: '202412240000',
            stdDt: '202412240000',
            accbPowerTotal: 0,
            totalPvPw: 0,
            btPw: 0,
            gridPw: 0,
            loadPw: 0,
            accbPowerAccum: 0,
            totalPvPwhDay: 0,
            btChrgPwhDay: 0,
            btDchrgPwhDay: 0,
            gridFdinPwhDay: 0,
            gridPurPwhDay: 0,
            loadPwhDay: 0,
            accbVoltageSingle: 0,
            accbCurrentSingle: 0,
            accbFrequencySingle: 0,
            meterActivePw: 0,
            meterReactivePw: 0,
            meterVR: 0,
            meterVS: 0,
            meterIR: 0,
            meterIS: 0,
            meterFreq: 0,
            meterPwfactor: 0,
            userSocAvg: 0,
            realSocAvg: 0,
            userSoc1: 0,
            userSoc2: 0,
            userSoc3: 0,
            userSoc4: 0,
            realSoc1: 0,
            realSoc2: 0,
            realSoc3: 0,
            realSoc4: 0,
            soh1: 0,
            soh2: 0,
            soh3: 0,
            soh4: 0,
            voltage1: 0,
            voltage2: 0,
            voltage3: 0,
            voltage4: 0,
            current1: 0,
            current2: 0,
            current3: 0,
            current4: 0,
            cellVoltageAvg1: 0,
            cellVoltageAvg2: 0,
            cellVoltageAvg3: 0,
            cellVoltageAvg4: 0,
            cellVoltageMax1: 0,
            cellVoltageMax2: 0,
            cellVoltageMax3: 0,
            cellVoltageMax4: 0,
            cellVoltageMin1: 0,
            cellVoltageMin2: 0,
            cellVoltageMin3: 0,
            cellVoltageMin4: 0,
            cellTemperatureAvg1: 0,
            cellTemperatureAvg2: 0,
            cellTemperatureAvg3: 0,
            cellTemperatureAvg4: 0,
            cellTemperatureMax1: 0,
            cellTemperatureMax2: 0,
            cellTemperatureMax3: 0,
            cellTemperatureMax4: 0,
            cellTemperatureMin1: 0,
            cellTemperatureMin2: 0,
            cellTemperatureMin3: 0,
            cellTemperatureMin4: 0,
            accbPowerWh: 0,
            totalPvWh: 0,
            btChrgWh: 0,
            btDchrgWh: 0,
            gridTrWh: 0,
            gridObWh: 0,
            loadWh: 0,
            batteryInfoList: null,
        },
    ],
};
