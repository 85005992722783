import type { EnvLocale, SelectorOption } from 'hems';

import type { ValueType } from 'hems/common/utils';

import { ENV_LOCALE } from './constant';

export const LANGUAGE = {
  EN: 'en',
  DE: 'de',
  FR: 'fr',
  KO: 'ko',
  PT: 'pt',
  MX: 'mx',
  IT: 'it',
  EU: 'eu',
} as const;

export type LanguageValue = ValueType<typeof LANGUAGE>;

export const DEFAULT_LANGUAGE = LANGUAGE.EN;

export const LANGUAGE_TEXT_MAP: { [key in LanguageValue]: string } = {
  [LANGUAGE.EN]: 'English',
  [LANGUAGE.DE]: 'Deutsch',
  [LANGUAGE.FR]: 'Français',
  [LANGUAGE.KO]: '한국어',
  [LANGUAGE.PT]: 'Português',
  [LANGUAGE.MX]: 'Español',
  [LANGUAGE.IT]: 'Italiano',
  [LANGUAGE.EU]: 'English',
} as const;

export const USLanguageOptions: SelectorOption<LanguageValue>[] = [
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.EN], value: LANGUAGE.EN },
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.MX], value: LANGUAGE.MX },
];

export const EUAULanguageOptions: SelectorOption<LanguageValue>[] = [
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.EN], value: LANGUAGE.EN },
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.DE], value: LANGUAGE.DE },
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.FR], value: LANGUAGE.FR },
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.PT], value: LANGUAGE.PT },
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.MX], value: LANGUAGE.MX },
];

export const EUAULanguageOptionsForAnnualReport: SelectorOption<LanguageValue>[] = [
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.DE], value: LANGUAGE.DE },
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.FR], value: LANGUAGE.FR },
  { text: `${LANGUAGE_TEXT_MAP[LANGUAGE.EU]}(EU)`, value: LANGUAGE.EU },
  { text: `${LANGUAGE_TEXT_MAP[LANGUAGE.EN]}(US)`, value: LANGUAGE.EN },
  { text: LANGUAGE_TEXT_MAP[LANGUAGE.PT], value: LANGUAGE.PT },
  /** @TODO: MX 개발 필요 */
];

export const INTL_LOCALE_MAP: { [key in LanguageValue | Extract<EnvLocale, 'AU'>]: string } = {
  [LANGUAGE.EN]: 'en',
  [LANGUAGE.DE]: 'de',
  [LANGUAGE.FR]: 'fr',
  [LANGUAGE.KO]: 'ko',
  [LANGUAGE.PT]: 'pt',
  [LANGUAGE.MX]: 'es-MX',
  [LANGUAGE.IT]: 'it',
  [LANGUAGE.EU]: 'en',
  [ENV_LOCALE.AU]: 'en-AU',
} as const;
