import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useAlertModal, useRole } from '@hems/util';

import { RoleWebMap } from '@/config/ConstantPortal';

// eslint-disable-next-line import/prefer-default-export
export const useDashboard = () => {
  const { t } = useI18n();

  const { roleName } = useRole();

  const noAccessAlertModal = useAlertModal({ content: t('message.no_results') });

  const dashboardUrl = computed(() => (roleName.value ? RoleWebMap[roleName.value] : ''));

  const goDashboardPageAfterLogin = () => {
    if (!dashboardUrl.value) {
      noAccessAlertModal.open();

      return;
    }
    window.location.replace(dashboardUrl.value);
  };

  return { goDashboardPageAfterLogin, dashboardUrl };
};
