import type { ValueType } from 'hems/common/utils';
/** 권한별 UI 기능 리스트 테이블 https://growingenergylabs.atlassian.net/wiki/x/wQE14wE */

export const ROLE_NAME = {
  USER: 'user',
  INSTALLER: 'installer',
  OPERATOR: 'operator',
  /** Utility (UI 지원X) */
  UTILITY: 'utility',
  CO_COMPANY: 'cocompany',
  DEV: 'dev',
  SERVICE: 'service',
  RETAILER: 'retailer',
  ADMIN: 'admin',
} as const;

export type RoleNameValue = ValueType<typeof ROLE_NAME>;

export const ROLE_CODE = {
  USER: '10',
  INSTALLER: '20',
  OPERATOR: '30',
  /** Utility (UI 지원X) */
  UTILITY: '40',
  CO_COMPANY: '50',
  DEV: '60',
  SERVICE: '70',
  RETAILER: '80',
  ADMIN: '90',
} as const;

export type RoleCodeValue = ValueType<typeof ROLE_CODE>;
