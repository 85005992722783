import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97a756ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-download-links-wrapper" }
const _hoisted_2 = { class: "app-download-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileAppLink = _resolveComponent("MobileAppLink")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "ty_4",
    style: {"opacity":"1","left":"50%","margin-left":"-440px","top":"60.5px","width":"500px","padding-top":"80px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobileAppTypeList, (mobileApp) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: mobileApp }, [
            (!_ctx.mobileAppLinkConfig[mobileApp].hidden)
              ? (_openBlock(), _createBlock(_component_MobileAppLink, {
                  key: 0,
                  class: "item",
                  "img-uri": _ctx.mobileAppLinkConfig[mobileApp].icon,
                  "img-qr-uri": _ctx.mobileAppLinkConfig[mobileApp].qr[_ctx.storeType],
                  "mobile-app-name": _ctx.mobileAppLinkConfig[mobileApp].name,
                  onClick: ($event: any) => (_ctx.openStoreUrl(mobileApp))
                }, null, 8, ["img-uri", "img-qr-uri", "mobile-app-name", "onClick"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('message.show_qrcode_when_mouse_over')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('message.move_appdownload_page_when_click')), 1)
          ])
        ])
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('close'))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}