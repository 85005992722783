import { DeviceService } from './DeviceService';
import { apiWrapper } from '../../util/helper';
export default class DeviceServiceInstaller extends DeviceService {
    constructor(axios) {
        super(axios);
    }
    async getRecentInstalledDeviceList(pageNum) {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/dashboards'), { pageNum });
        return data;
    }
    async installDevice(params) {
        const { data } = await this.post(apiWrapper.managerApi('/devices/installations/ems'), params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    }
    // 장비 목록
    async getDeviceList() {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/excel-files'));
        return data;
    }
    async registerSite(params) {
        const { data } = await this.post(apiWrapper.managerApi('/managements/mobiles/pro-apps/sites'), params);
        return data;
    }
    async registerDemoSite(params) {
        const { data } = await this.post(apiWrapper.managerApi('/managements/mobiles/pro-apps/sites'), params);
        return data;
    }
    async updateStepData(siteId, params) {
        const { data } = await this.post(apiWrapper.managerApi('/managements/mobiles/pro-apps/steps'), {
            siteId,
            stepData: params,
        });
        return data;
    }
    async checkValidAcDevice(deviceId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/validations/${deviceId}/ac-device-id`));
        return data;
    }
}
