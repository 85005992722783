import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd2d2412"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-menu" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.text,
          class: _normalizeClass(`list-item ${_ctx.selectedValue === item.value && 'is-selected'}`),
          onClick: ($event: any) => (_ctx.$emit('click', item.value))
        }, [
          _createTextVNode(_toDisplayString(item.text) + " ", 1),
          _createVNode(_component_Image2, {
            class: "check-icon",
            src: "icons/ic_check",
            extension: "svg"
          })
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}