import { defineComponent, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Calendar, Selector } from '@hems/component';
import { Helper, useLocale, useSiteInfo } from '@hems/util';
import { DATE_UNIT, DAY_RANGE_31, GRAPH_TERM_UNIT } from '@hems/util/src/constant';
import { addDate, addFromDate, defaultStartDateTimeOptions, getDateWithAdjustedTime, subtractDate, getDateDifference, today, getLocalDateFormat, now, } from '@hems/util/src/helper/dateHelper';
const { VCalendarRange, VCalendar } = Calendar;
export default defineComponent({
    name: 'GraphSearchBox',
    components: {
        Selector,
        VCalendarRange,
        VCalendar,
    },
    props: {
        condition: {
            type: Object,
            required: true,
        },
    },
    emits: ['changeCalendarMode', 'changeDate'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const currentDate = now();
        const startDate = getDateWithAdjustedTime(subtractDate(6, 'days'), defaultStartDateTimeOptions);
        const endDate = addDate(1, 'hours');
        const searchCondition = ref(props.condition);
        const isDayCalendarMode = computed(() => searchCondition.value.calendarMode === GRAPH_TERM_UNIT.MINUTE);
        const { locale } = useLocale();
        const dateFormat = computed(() => getLocalDateFormat(isDayCalendarMode.value ? { locale } : { locale, isTime: true, isSecond: false }));
        const { timezoneId } = useSiteInfo();
        const calendarModeOption = computed(() => [
            { value: GRAPH_TERM_UNIT.MINUTE, text: t('common.day') },
            { value: GRAPH_TERM_UNIT.HOUR, text: t('common.period') },
        ]);
        const getMinDate = () => {
            if (isDayCalendarMode.value) {
                return addFromDate(subtractDate(3, 'months'), 1, 'days');
            }
            return addFromDate(subtractDate(1, 'years'), 1, 'days');
        };
        const minDate = ref(getMinDate());
        const validateRangeDate = (value) => {
            const dateCount = getDateDifference(value, DATE_UNIT.DAYS);
            if (dateCount > DAY_RANGE_31) {
                return false;
            }
            return true;
        };
        const onChangeCalendarMode = (value) => {
            emit('changeCalendarMode', value);
            minDate.value = getMinDate();
            if (isDayCalendarMode.value) {
                searchCondition.value.date.start = today();
                emit('changeDate', searchCondition.value.date);
                return;
            }
            searchCondition.value.date = {
                start: startDate,
                end: endDate,
            };
            emit('changeDate', searchCondition.value.date);
        };
        const onChangeDate = () => {
            emit('changeDate', searchCondition.value.date);
        };
        return {
            searchCondition,
            minDate,
            Helper,
            GRAPH_TERM_UNIT,
            locale,
            calendarModeOption,
            isDayCalendarMode,
            timezoneId,
            currentDate,
            dateFormat,
            getLocalDateFormat,
            validateRangeDate,
            onChangeCalendarMode,
            onChangeDate,
        };
    },
});
