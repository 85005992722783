
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import type { DisplayedSiteErrorInfo } from 'hems/event';

import BasicChip from './BasicChip.vue';

export default defineComponent({
  name: 'ErrorCodeChipsGroup',
  components: {
    BasicChip,
  },
  props: {
    displayedSiteErrorInfo: {
      type: Array as PropType<DisplayedSiteErrorInfo[]>,
      required: true,
    },
  },
});
