import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26eafc6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pop_tit" }
const _hoisted_2 = { style: {"text-align":"left","margin-top":"2rem"} }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "btn_wrap_m mt50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, { class: "join_wrap privacy-policy-popup" }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('message.terms_personal_privacy')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          innerHTML: _ctx.privacyPolicyTerms?.terms_contents
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          class: "btn_m btn_c btn_w_2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
        }, _toDisplayString(_ctx.$t('common.close')), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}