import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Selector, useMessageBox, Button, Panel } from '@hems/component';
import { useRole } from '@hems/util';
import { DATE_UNIT, DAY_RANGE_31 } from '@hems/util/src/constant';
import { ERROR_LEVEL_SELECTOR_OPTIONS, ERROR_RESOLVED_FILTER } from '@hems/util/src/constant/eventHistory';
import { getLocalDateFormat, now, getDateDifference, defaultPeriod, sixMonthsPeriod, thirtyOneDaysPeriod, } from '@hems/util/src/helper/dateHelper';
import { codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'EventBySiteSearchBox',
    components: {
        Panel,
        Selector,
        Button,
    },
    props: {
        condition: {
            type: Object,
            required: true,
        },
    },
    emits: ['search'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const messageBox = useMessageBox();
        const searchCondition = ref({
            ...props.condition,
            date: props.condition.date ?? defaultPeriod,
        });
        const { isAdminGroup } = useRole();
        const maxDate = now();
        const errorResolvedFilterOptions = [
            { text: t('common.current'), value: ERROR_RESOLVED_FILTER.CURRENT },
            { text: t('common.resolved'), value: ERROR_RESOLVED_FILTER.RESOLVED },
        ];
        const errorLevelOptions = computed(() => [{ text: t('common.all'), value: '' }, ...codeNamesToSelectorOptions(ERROR_LEVEL_SELECTOR_OPTIONS, t)]);
        const validateRangeDate = (value) => {
            const dateDifference = getDateDifference(value, DATE_UNIT.DAYS);
            if (dateDifference > DAY_RANGE_31) {
                messageBox.alert(t('message.period_not_exceed')).open();
                return false;
            }
            return true;
        };
        const onSearch = () => {
            searchCondition.value.date =
                searchCondition.value.errorResolved === ERROR_RESOLVED_FILTER.CURRENT ? sixMonthsPeriod : thirtyOneDaysPeriod;
            emit('search', { ...searchCondition.value });
        };
        return {
            searchCondition,
            errorLevelOptions,
            maxDate,
            errorResolvedFilterOptions,
            isAdminGroup,
            validateRangeDate,
            onSearch,
            getLocalDateFormat,
        };
    },
});
