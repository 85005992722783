/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { Issuer, Role, WebContextRoot } from 'hems';

import type { ValueType } from 'hems/common/utils';

export const RoleWebMap: Record<Role, WebContextRoot> = {
  admin: '/admin',
  utility: '/admin',
  operator: '/admin',
  cocompany: '/admin',
  dev: '/admin',
  installer: '/installer',
  user: '/user',
  service: '/admin',
  retailer: '/admin',
};

export const isAU =
  (['local', 'development'].includes(process.env.VUE_APP_ENV ?? '') && process.env.VUE_APP_LOCALE === 'AU') ||
  (process.env.VUE_APP_ENV === 'production' && process.env.VUE_APP_LOCALE === 'AU');

/** Application Store Type */
export const APP_STORE_TYPE = {
  GOOGLE: 'google',
  APPLE: 'apple',
} as const;

export type AppStoreType = ValueType<typeof APP_STORE_TYPE>;

export const JOIN_TYPE = {
  GENERAL: 'general',
  INSTALLER: 'installer',
} as const;

export type JoinType = ValueType<typeof JOIN_TYPE>;

export const AUTH_CREDENTIAL_INFO = {
  ID: 'id',
  PW: 'pw',
} as const;

export type AuthCredentialInfoType = ValueType<typeof AUTH_CREDENTIAL_INFO>;

export const MOBILE_APP_TYPE = {
  GO: 'go',
  PRO: 'pro',
  HOME: 'home',
} as const;

export type MobileAppType = ValueType<typeof MOBILE_APP_TYPE>;

export const LOGIN_PROVIDER: Record<Issuer, Issuer> = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
  QOMMAND: 'QOMMAND',
} as const;

export type LoginProviderType = ValueType<typeof LOGIN_PROVIDER>;
