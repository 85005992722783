import type { SelectorOption } from 'hems';

export const NOTICE_TARGET_WEB_VALUES = ['All', 'Home Web', 'Pro Web'];
export const targetOptions: SelectorOption[] = [
  { text: 'All', value: 'All' },
  // 단일 공지를 위한 Target 옵션 All only 처리
  // { text: 'Home App', value: 'Home App' },
  // { text: 'Pro App', value: 'Pro App' },
  // { text: 'Home Web', value: 'Home Web' },
  // { text: 'Pro Web', value: 'Pro Web' },
];
