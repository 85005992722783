/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { ValueType } from 'hems/common/utils';

export type ErrorMessage = string | string[];

export class ErrorException extends Error {
  _message: ErrorMessage = '';

  constructor(name: string, message: ErrorMessage) {
    if (Array.isArray(message)) super(message.join(','));
    else super(message);

    this._message = message;
    this.name = name;
  }
  public getMessageJoin(divider: string): string {
    return typeof this._message === 'string' ? this._message : this._message?.join(divider) ?? '';
  }
}

export class UnknownErrorException extends ErrorException {
  constructor() {
    super('UnknownErrorException', 'Unknown error occurred.');
  }
}
export class UnauthorizedException extends ErrorException {
  constructor(message?: ErrorMessage) {
    super('UnauthorizedException', message || 'Unauthorized');
  }
}
export class ForbiddenException extends ErrorException {
  constructor(message?: ErrorMessage) {
    super('ForbiddenException', message || 'Forbidden');
  }
}

export class NoArgumentsException extends ErrorException {
  constructor(message?: ErrorMessage) {
    super('NoArgumentsException', message || 'NoArguments');
  }
}

export class AccessTokenParseException extends ErrorException {
  constructor(message?: ErrorMessage) {
    super('AccessTokenParseException', message || 'NoArguments');
  }
}

export class UnsupportedGenType extends ErrorException {
  constructor(message?: ErrorMessage | null) {
    super('UnsupportedGenType', `Unspported Gen Type: ${message}`);
  }
}
export class UnsupportedSettingsMessageType extends ErrorException {
  constructor(message?: ErrorMessage) {
    super('UnsupportedSettingsMessageType', message || 'NoArguments');
  }
}

export class SocialLoginException extends ErrorException {
  constructor(message?: ErrorMessage) {
    super('SocialLoginException', message || 'This account is not registered.');
  }
}

export class AxiosErrorException extends ErrorException {
  private _url: string;
  private _params?: Record<string, any>;
  private _status: number;
  private _statusText: string;
  private _originError: any | unknown;
  private _data: any | unknown;
  constructor(url: string, params?: Record<string, any>, e?: any | unknown, message?: string) {
    super('AxiosErrorException', message || e.response.statusText);
    this._url = url;
    this._params = params;
    this._status = e.response.status;
    this._statusText = e.response.statusText;
    this._originError = e;
    this._data = e.response.data;
  }
  get url(): string {
    return this._url;
  }
  get params(): Record<string, any> | undefined {
    return this._params;
  }
  get status(): number {
    return this._status;
  }
  get statusText(): string {
    return this._statusText;
  }
  get originError(): any | unknown {
    return this._originError;
  }
  get data(): any | unknown {
    return this._data;
  }

  afterError(fnMap: Record<number, () => void>): boolean {
    if (fnMap[this._status]) {
      fnMap[this._status]();

      return true;
    }

    return false;
  }
}

export class UnregisteredProductModel extends ErrorException {
  constructor() {
    super('NonExistProductModel', 'Unregistered Product model.');
  }
}

export const STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  WARNING: 235,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  DUPLICATED: 409,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
} as const;

export type StatusCodeValueType = ValueType<typeof STATUS_CODE>;

export const PROMISE_SETTLED_STATUS = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
} as const;

export type PromiseSettledStatusValueType = ValueType<typeof PROMISE_SETTLED_STATUS>;

export const STATUS_TEXT = {
  OK: 'ok',
  ERROR: 'error',
} as const;

export type StatusTextValueType = ValueType<typeof STATUS_TEXT>;
