import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d78d983"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-title-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
    (_ctx.showInfo)
      ? (_openBlock(), _createBlock(_component_Image2, {
          key: 0,
          class: "info-icon",
          src: "icons/ic_re_info",
          extension: "svg",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickInfoIcon')))
        }))
      : _createCommentVNode("", true)
  ]))
}