import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "pop_tit" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Step1TermAndCondition = _resolveComponent("Step1TermAndCondition");
    const _component_Step2RequiredData = _resolveComponent("Step2RequiredData");
    const _component_PopupWrapper = _resolveComponent("PopupWrapper");
    return (_openBlock(), _createBlock(_component_PopupWrapper, {
        class: "join_wrap",
        style: { "opacity": "1", "left": "50%", "margin-left": "-640px", "top": "190px", "margin-bottom": "50px" }
    }, {
        default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('account.join_member')), 1),
            (_ctx.state.step === 1)
                ? (_openBlock(), _createBlock(_component_Step1TermAndCondition, {
                    key: 0,
                    data: _ctx.state.termAndCondition,
                    locale: _ctx.locale,
                    "terms-list": _ctx.termsList,
                    onNext: _ctx.onNext
                }, null, 8, ["data", "locale", "terms-list", "onNext"]))
                : _createCommentVNode("", true),
            (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                    (_ctx.state.step === 2)
                        ? (_openBlock(), _createBlock(_component_Step2RequiredData, {
                            key: 0,
                            social: _ctx.social,
                            data: _ctx.state.essentialData,
                            onNext: _ctx.onNext,
                            onBack: _ctx.onBack
                        }, null, 8, ["social", "data", "onNext", "onBack"]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            })),
            _createElementVNode("button", {
                type: "button",
                class: "close_btn_2",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
            }, _toDisplayString(_ctx.$t('common.close')), 1)
        ]),
        _: 1
    }));
}
