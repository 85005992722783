// FIXME 타입 에러 수정 후 SettingsSmartModuleACSystemResponse 타입 지정
export const case1DeviceConfiguration = {
  feedin_relay_attach_level_relay1: 0,
  debug_mode_modbus: 0,
  energy_policy: 1,
  feedin_relay_attach_level_relay3: 0,
  debug_mode_mqtt: 0,
  feedin_relay_attach_level_relay2: 0,
  dc_injection_control_flag: 0,
  feedin_relay_attach_level_relay4: 0,
  log_level_mlpe_mgr: 4,
  hub_generator_nameplate_power: 0,
  anti_islanding_freq_feedback_qmax: 0,
  active_power_frequency_sh: 0,
  voltage_rise_suppression_delay_time: 0,
  active_power_frequency_sl: 0,
  update_dt: 1737071880430,
  pv_meter_modbus_tcp_ip: '',
  anti_islanding_freq_feedback_function: 0,
  inverter_control_v_ar_act: 0,
  site_configuration_disable_mode: 0,
  grid_frequency_under_frequency_detection_level4: 0,
  grid_frequency_under_frequency_detection_level5: 0,
  inverter_control_pcs_var_max_q3: 0,
  hub_ess_inverter: 'null',
  inverter_control_pcs_var_max_q4: 0,
  external_ems_modbus_rtu_parity: 'N',
  version: '4.7.0',
  limit_control_ess_import_limit: 0,
  external_ems_modbus_rtu_stop: 1,
  pv_string_power5: 0,
  log_level_csip_mgr: 4,
  grid_frequency_over_frequency_detection_time5: 0,
  pv_string_power4: 0,
  pv_string_power3: 0,
  grid_frequency_over_frequency_detection_time3: 0,
  active_power_frequency_of_hysteresis_time: 0,
  pv_string_power2: 0,
  grid_frequency_over_frequency_detection_time4: 0,
  pv_string_power1: 0,
  afci_ch1_time_area_threshold_gain: 0,
  reactive_power_cospi_p_flag: 0,
  grid_voltage_under_voltage_release_time: 0,
  rcmu_continuous_flag: 0,
  inverter_control_export_pmin: 0,
  battery_backup_soc: 0,
  log_level_db_mgr: 1,
  grid_frequency_under_frequency_detection_level3: 0,
  grid_voltage_over_voltage_detection_time4: 0,
  grid_voltage_over_voltage_detection_time3: 0,
  pv_insulation_fault_level: 0,
  limit_control_export_hard_limit_level: 0,
  gradient_flag: 0,
  grid_voltage_over_voltage_detection_time5: 0,
  reactive_power_q_p_load_y2: 0,
  reactive_power_q_p_load_y1: 0,
  inverter_grid_target_frequency: 60,
  reactive_power_q_p_load_y3: 0,
  dynamic_reactive_current_ar_gra_sag: 0,
  external_ems_modbus_tcp_port: 0,
  cls_export_limit: 5000,
  pv_type: 1,
  log_level_process_mgr: 4,
  derating_start_temp: 0,
  pv_meter_model: 0,
  external_control_pv_meter_load: 0,
  fcas_test_mode: 0,
  pv_insulation_time: 0,
  dc_injection_flag: 0,
  grid_frequency_under_frequency_detection_time3: 0,
  log_level_system_log: 4,
  grid_frequency_under_frequency_detection_time4: 0,
  reactive_power_q_p_load_x1: 0,
  afci_ch3_frequency_area_threshold_percent: 0,
  limit_control_ess_export_limit: 0,
  grid_frequency_under_frequency_detection_time5: 0,
  reactive_power_q_p_load_x3: 0,
  micro_inverter_nameplate_ratings_vmax: 120,
  reactive_power_q_p_load_x2: 0,
  meter_modbus_tcp_ip: '',
  voltage_rise_suppression_min_active_power: 0,
  grid_voltage_ten_min_voltage_detection_level: 0,
  inverter_control_pf_min_q3: 0,
  limit_control_export_soft_limit_level: 0,
  inverter_import_power_limit: -7600,
  inverter_control_pf_min_q4: 0,
  advanced_setting: [],
  meter_modbus_rtu_baudrate: 9600,
  fcas_droop_rate_raise: 0.699999988079071,
  external_ems_modbus_tcp_ip: '',
  grid_voltage_under_voltage_detection_time5: 0,
  grid_voltage_under_voltage_detection_time4: 0,
  rcmu_sudden_detection_time3: 0,
  pv_meter_modbus_rtu_dev: '/dev/ttymxc0',
  active_power_voltage_x1: 0,
  rcmu_sudden_detection_time1: 0,
  hub_external_ct_installed_point: 0,
  active_power_voltage_x2: 0,
  rcmu_sudden_detection_time2: 0,
  cls_import_limit: 18400,
  limit_control_export_soft_limit_flag: 0,
  accb_polarity_reverse_consumption_l1_ct: 1,
  voltage_rise_suppression_active_power_response_time: 0,
  log_level_meter_mgr: 4,
  battery_installed_rack_count: 1,
  reactive_power_q_set_point_excited_select: 1,
  accb_extended_production_ct_flag: 1,
  auto_fw_update_flag: 1,
  meter_modbus_tcp_port: 0,
  meter_modbus_rtu_stop: 1,
  log_level_cloud_mgr: 0,
  msp_der_breaker_rating: 20,
  external_ems_meter2: 0,
  external_ems_meter1: 0,
  anti_islanding_gain2: 0,
  inverter_multiple_earthed_neutral_system: 0,
  log_level_gateway_mgr: 4,
  reactive_power_cospi_p_response_time: 0,
  log_level_fota: 4,
  anti_islanding_gain1: 0,
  tou_end_date: '',
  active_power_voltage_y1: 0,
  battery_user_soc_min: 5,
  eos_power_sampling_interval: 60000,
  active_power_voltage_y2: 0,
  log_level_ess_mgr: 4,
  pv_meter_modbus_rtu_data: 8,
  ems_priority_id: 0,
  msp_msp_breaker_rating: 200,
  active_power_frequency_of_hysteresis_frequency: 0,
  country_code: 840,
  rcmu_sudden_detection_level1: 0,
  log_level_algorithm_mgr: 1,
  rcmu_sudden_detection_level2: 0,
  rcmu_sudden_detection_level3: 0,
  afci_ch2_time_area_threshold_gain: 0,
  external_control_modbus_rtu_baudrate: 115200,
  external_ems_connection: 0,
  msp_pv_breaker_rating: 0,
  afci_ch3_frequency_area_threshold_gain: 0,
  pv_insulation_check_cnt: 0,
  anti_islanding_step_injection_function: 0,
  fcas_nofb_min: 49.849998474121094,
  battery_discharge_limit: 7833,
  msp_msp_busbar_rating: 200,
  derating_value: 0,
  inverter_enter_service_randomized_delay: 0,
  afci_ch2_flag: 0,
  sg_ready_heat_pump_consumption_power: 0,
  external_ems_model: 0,
  msp_busbar_protection_flag: 1,
  afci_ch1_flag: 0,
  rcmu_continuous_detection_time: 0,
  external_ems_modbus_rtu_baudrate: 9600,
  voltage_rise_suppression_flag: 0,
  dc_optimizer_slv_tx_gain: 0,
  afci_ch1_frequency_area_threshold_percent: 0,
  voltage_rise_suppression_min_pf: 0,
  secondary_ems_server_id: 1,
  inverter_max_output_power: 7600,
  inverter_export_power_limit: 7600,
  accb_polarity_reverse_consumption_l2_ct: 1,
  install_done: 1,
  dynamic_opt_flag: 0,
  limit_control_generation_hard_limit_time: 0,
  external_control_meter_load: 0,
  reactive_power_cospi_p_excited_select: 0,
  dynamic_reactive_current_db_v_min: 0,
  meter_modbus_rtu_data: 8,
  meter_model: 0,
  voltage_rise_suppression_pf_response_time: 0,
  fcas_oftb_min: 49,
  active_power_frequency_uf_pref_select: 0,
  pv_meter_modbus_rtu_baudrate: 9600,
  feedin_relay_detach_level_relay1: 0,
  feedin_relay_detach_level_relay2: 0,
  pv_meter_connection: 0,
  feedin_relay_detach_level_relay3: 0,
  pcs_connection_mode: 0,
  rcmu_sudden_flag: 0,
  external_ems_server_id: 0,
  debuginfo_echonetmgr: 1,
  limit_control_generation_hard_limit_flag: 0,
  dc_injection_detection_level2: 0,
  voltage_rise_suppression_vref: 0,
  anti_islanding_step_injection_qmax: 0,
  pv_meter_modbus_rtu_stop: 1,
  limit_control_export_hard_limit_time: 0,
  afci_ch1_frequency_area_threshold_gain: 0,
  limit_control_generation_soft_limit_level: 0,
  micro_inverter_watt_set_mod: 0,
  grid_frequency_over_frequency_release_time: 0,
  dc_injection_detection_level1: 0,
  gradient_active_power_set_point_gradient: 0,
  grid_frequency_under_frequency_release_time: 0,
  log_level_data_accumulator: 4,
  gradient_energy_source_change: 0,
  pv_connection_type: 2,
  battery_hysteresis_high: 90,
  active_power_frequency_uf_hysteresis_frequency: 0,
  external_control_modbus_rtu_data: 8,
  active_power_frequency_uf_hysteresis_time: 0,
  pv_module_count: 0,
  fcas_droop_rate_lower: 0.699999988079071,
  afci_ch2_frequency_area_threshold_percent: 0,
  log_level_sys_mgr: 4,
  dynamic_reactive_current_ar_gra_swell: 0,
  log_level_power_control: 4,
  micro_inverter_w_rmp_ref: 0,
  pv_meter_modbus_tcp_port: 0,
  micro_inverter_total_max_output_power: 19200,
  external_control_modbus_rtu_parity: 'N',
  debuginfo_sysmgr: 4,
  storm_cover_charge_type: 0,
  site_info_backup_config_type: 0,
  pv_insulation_flag: 0,
  afci_arc_energy_region: 0,
  feedin_relay_detach_level_relay4: 0,
  external_control_modbus_tcp_port: 0,
  tou_weekend_count: 0,
  anti_islanding_reactive_power_suppression: 0,
  dynamic_reactive_current_flag: 0,
  debuginfo_mlpemgr: 4,
  log_level_ipc_bridge: 4,
  dc_injection_detection_time1: 0,
  dc_injection_detection_time2: 0,
  external_control_connection: 0,
  tou_weekday_count: 0,
  pv_feed_in_limit: -1,
  grid_voltage_over_voltage_release_time: 0,
  frt_start_level_hfrt: 0,
  log_level_diag_monitor: 4,
  dynamic_reactive_current_filter_tms: 0,
  secondary_ems_modbus_tcp_port: 0,
  pv_feed_in_limit_w: 100,
  dc_optimizer_mst_tx_gain: 0,
  accb_flag: 0,
  frt_start_level_hvrt: 0,
  micro_inverter_nameplate_ratings_vmin: 0,
  sg_ready_flag: 0,
  afci_ch3_flag: 0,
  micro_inverter_es_rmp_tms: 0.33,
  pcs_debug_mode: 0,
  fcas_fr_min: 49.5,
  active_power_frequency_slope_select: 0,
  external_ems_modbus_rtu_dev: '/dev/ttymxc0',
  accb_polarity_reverse_production_ct: 1,
  limit_control_generation_soft_limit_flag: 0,
  inverter_control_discharge_rate_setting: 0,
  limit_control_export_soft_limit_time: 0,
  meter_modbus_rtu_dev: '/dev/ttymxc3',
  external_control_modbus_rtu_stop: 1,
  rcmu_continuous_detection_level: 0,
  inverter_permit_service_mode_select: 1,
  battery_charge_limit: -7833,
  ul3141_ess_mode: 0,
  update_id: 'system',
  inverter_control_va_setting: 0,
  accb_polarity_reverse_ext_production_ct: 1,
  inverter_external_generator_mode: 0,
  active_power_frequency_of_pref_select: 0,
  log_level_energy_monitor: 4,
  active_power_frequency_hysteresis_select: 0,
  fcas_fr_max: 50.5,
  external_ems_modbus_rtu_data: 8,
  pv_capacity_calc_option: 0,
  log_level_dc_source_mgr: 1,
  pv_inverter_nameplate_power: 19200,
  reactive_power_cospi_p_y3: 0,
  storm_cover_flag: 0,
  external_control_modbus_rtu_dev: '/dev/ttymxc0',
  limit_control_export_hard_limit_flag: 0,
  ul1741_flag: 0,
  voltage_rise_suppression_release_time: 0,
  pv_meter_modbus_rtu_parity: 'N',
  battery_user_soc_max: 100,
  secondary_ems_modbus_tcp_ip: '',
  reactive_power_cospi_p_y2: 0,
  reactive_power_cospi_p_y1: 0,
  accb_consumption_ct_installed_point: 1,
  log_level_gqa: 1,
  dynamic_reactive_current_hold_tmms: 0,
  meter_modbus_rtu_parity: 'N',
  dc_injection_control_level: 0,
  grid_voltage_over_voltage_detection_level5: 0,
  battery_hysteresis_low: 15,
  grid_voltage_over_voltage_detection_level4: 0,
  grid_voltage_over_voltage_detection_level3: 0,
  active_power_frequency_x2: 59.9640007019043,
  active_power_frequency_x5: 60.0359992980957,
  site_info_grid_support_sub_panel: 0,
  afci_ch2_frequency_area_threshold_gain: 0,
  active_power_frequency_x6: 60.0359992980957,
  eos_flag: 0,
  grid_voltage_under_voltage_detection_level5: 0,
  reactive_power_cospi_p_x3: 0,
  grid_voltage_under_voltage_detection_level4: 0,
  reactive_power_cospi_p_x2: 0,
  dynamic_reactive_current_ar_gra_mod: 0,
  active_power_frequency_x1: 59.9640007019043,
  afci_ch3_time_area_threshold_gain: 0,
  reactive_power_cospi_p_x1: 0,
  inverter_tau_coefficient: 0,
  log_level_energy_optimizer: 4,
  fcas_flag: 0,
  debuginfo_powerscheduler: 0,
  derating_flag: 0,
  log_level_energy_scheduler: 4,
  anti_islanding_passive_method_detection: 0,
  pv_string_count: 0,
  dynamic_reactive_current_db_v_max: 0,
  frt_start_level_lfrt: 0,
  meter_connection: 2,
  limit_control_generation_soft_limit_time: 0,
  log_level_energy_link: 4,
  log_level_web_engine: 4,
  fcas_nofb_max: 50.150001525878906,
  micro_inverter_ena_flag_dyn_curr_event_base: 0,
  grid_frequency_over_frequency_detection_level5: 0,
  grid_frequency_over_frequency_detection_level4: 0,
  dc_optimizer_user_rsd: 0,
  grid_frequency_over_frequency_detection_level3: 0,
  inverter_logic_interface: 0,
  limit_control_generation_hard_limit_level: 0,
  frt_start_level_lvrt: 0,
  pv_feed_in_limit_flag: 1,
  inverter_control_import_pmin: 0,
  pv_module_power: 0,
  tou_start_date: '',
  feedin_relay_flag: 0,
  eos_default_sampling_interval: 60000,
  inverter_control_charge_rate_setting: 0,
  msp_ess_breaker_rating: 0,
  inverter_control_grid_target_voltage_offset: 0,
  external_ems_grid_code: 0,
  fcas_oftb_max: 51,
  fcas_response_capacity_limit: 0,
};
