import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRole } from '@hems/util/src/composable/role';
import { ROLE_NAME } from '@hems/util/src/constant/role';
// eslint-disable-next-line import/prefer-default-export
export const useNavigationMenu = () => {
    const { t } = useI18n();
    const { roleName } = useRole();
    const store = useStore();
    const userName = computed(() => store.state.user.userId);
    const isDemoAccount = computed(() => roleName.value === ROLE_NAME.INSTALLER && userName.value === process.env.VUE_APP_INSTALLER_DEMO_ACCOUNT_NAME);
    const allNavigationConfig = computed(() => [
        {
            id: 'remote',
            label: t('common.monitor'),
            name: 'MonitoringList',
            children: [
                {
                    id: 'remote-monitoring',
                    label: t('common.site'),
                    name: 'MonitoringList',
                    permissions: [
                        ROLE_NAME.ADMIN,
                        ROLE_NAME.DEV,
                        ROLE_NAME.OPERATOR,
                        ROLE_NAME.SERVICE,
                        ROLE_NAME.RETAILER,
                        ROLE_NAME.INSTALLER,
                    ],
                },
                {
                    id: 'remote-event',
                    label: t('common.error_history'),
                    name: 'EventList',
                },
                {
                    id: 'remote-group-update',
                    label: t('common.remote_update'),
                    name: 'RemoteUpdateList',
                    permissions: [ROLE_NAME.ADMIN, ROLE_NAME.DEV, ROLE_NAME.SERVICE],
                },
            ],
        },
        {
            id: 'device',
            label: t('common.installation'),
            name: 'SiteList',
            permissions: [ROLE_NAME.INSTALLER],
            hide: !isDemoAccount.value,
            children: [
                {
                    id: 'install-pro-smart',
                    label: 'Q.HOME SMART',
                    name: 'SiteList',
                    permissions: [ROLE_NAME.INSTALLER],
                    hide: !isDemoAccount.value,
                },
                {
                    id: 'install-pro-demo',
                    label: 'Q.HOME SMART (TEST)',
                    name: 'SiteDetailDemo',
                    permissions: [ROLE_NAME.INSTALLER],
                    hide: !isDemoAccount.value,
                },
            ],
        },
        {
            id: 'user',
            label: t('common.account'),
            name: 'CustomerList',
            children: [
                {
                    id: 'user-homeowner',
                    label: t('common.homeowner'),
                    name: 'CustomerList',
                    permissions: [
                        ROLE_NAME.ADMIN,
                        ROLE_NAME.DEV,
                        ROLE_NAME.OPERATOR,
                        ROLE_NAME.SERVICE,
                        ROLE_NAME.RETAILER,
                        ROLE_NAME.INSTALLER,
                    ],
                },
                {
                    id: 'user-installer',
                    label: t('account.installer'),
                    name: 'InstallerAccountList',
                    permissions: [ROLE_NAME.ADMIN, ROLE_NAME.DEV, ROLE_NAME.SERVICE],
                },
            ],
        },
        {
            id: 'maintenance',
            label: t('common.activity'),
            name: 'ActivityList',
            children: [
                {
                    id: 'maintenance-activity',
                    label: t('common.history_list'),
                    name: 'ActivityList',
                },
            ],
        },
        {
            id: 'management',
            label: t('common.management'),
            name: 'NoticePopup',
            permissions: [ROLE_NAME.ADMIN, ROLE_NAME.DEV, ROLE_NAME.SERVICE],
            children: [
                {
                    id: 'notice-popup',
                    label: t('common.popup'),
                    name: 'NoticePopup',
                    permissions: [ROLE_NAME.ADMIN, ROLE_NAME.DEV],
                },
                {
                    id: 'terms-policy',
                    label: t('common.terms_and_policy'),
                    name: 'TermsPolicy',
                    permissions: [ROLE_NAME.ADMIN, ROLE_NAME.DEV],
                },
                {
                    id: 'web-hmi-password',
                    label: t('common.web_hmi_password'),
                    name: 'WebHmiPassword',
                    permissions: [ROLE_NAME.ADMIN, ROLE_NAME.DEV, ROLE_NAME.SERVICE],
                },
            ],
        },
        // 모바일 앱 Support 기능 배포 일정에 맞춰 함께 배포
        // {
        //   id: 'support',
        //   label: t('Support'),
        //   name: 'SupportGuide',
        //   permissions: [ROLE_NAME.ADMIN, ROLE_NAME.DEV],
        //   children: [
        //     {
        //       id: 'guide',
        //       label: t('Guide'),
        //       name: 'SupportGuide',
        //     },
        //   ],
        // },
    ]);
    const filterByRoleName = (item, roleName) => {
        // admin, installer 모든 권한에서 접근 가능
        if (!item?.permissions || item?.permissions.length === 0) {
            return true;
        }
        if (item?.hide) {
            return false;
        }
        return item.permissions.includes(roleName);
    };
    const navigationConfigByRole = computed(() => allNavigationConfig.value
        .filter((item) => filterByRoleName(item, roleName.value))
        .map((item) => {
        if (item?.children) {
            return {
                ...item,
                children: item.children.filter((child) => filterByRoleName(child, roleName.value)),
            };
        }
        return item;
    }));
    return { allNavigationConfig, navigationConfigByRole, isDemoAccount };
};
