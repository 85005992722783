import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11a3d6c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pop_tit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountInfo = _resolveComponent("AccountInfo")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "join_wrap",
    style: {"width":"780px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('account.account_setting')), 1),
      _createVNode(_component_AccountInfo, {
        data: _ctx.state.accountInfo,
        "role-nm": _ctx.roleNm,
        onSave: _ctx.saveAccountInfo,
        onWithdrawal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('withdrawal')))
      }, null, 8, ["data", "role-nm", "onSave"]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}