import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeneralAccountInfoPopup = _resolveComponent("GeneralAccountInfoPopup")!
  const _component_InstallerAccountInfoPopup = _resolveComponent("InstallerAccountInfoPopup")!
  const _component_PrivacyPolicyPopup = _resolveComponent("PrivacyPolicyPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.isUser)
          ? (_openBlock(), _createBlock(_component_GeneralAccountInfoPopup, {
              key: 0,
              onOpenPrivacyPopup: _ctx.openPrivacyPopup,
              onWithdrawal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('withdrawal'))),
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
            }, null, 8, ["onOpenPrivacyPopup"]))
          : (_ctx.state.roleNm)
            ? (_openBlock(), _createBlock(_component_InstallerAccountInfoPopup, {
                key: 1,
                "role-nm": _ctx.state.roleNm,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
                onWithdrawal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('withdrawal')))
              }, null, 8, ["role-nm"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })),
    (_ctx.state.privacyPopup)
      ? (_openBlock(), _createBlock(_component_PrivacyPolicyPopup, {
          key: 0,
          onClose: _ctx.closePrivacyPopup
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}