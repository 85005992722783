import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fbe46df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-code-container" }
const _hoisted_2 = { class: "error-code-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicChip = _resolveComponent("BasicChip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedSiteErrorInfo, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.errorCode,
        class: "error-code-chips-group"
      }, [
        _createVNode(_component_BasicChip, {
          contents: item.errorCode
        }, null, 8, ["contents"]),
        _createElementVNode("div", _hoisted_2, "(" + _toDisplayString(item.triggerDate) + ")", 1)
      ]))
    }), 128))
  ]))
}