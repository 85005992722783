import { defineComponent, computed } from 'vue';
import { EnergyIndependenceBar, InformationImage } from '@hems/component';
import { ENERGY_VALUE_IMAGE, UNIT } from '@hems/util/src/constant';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
export default defineComponent({
    name: 'EnergyIndependence',
    components: {
        EnergyIndependenceBar,
        InformationImage,
    },
    props: {
        energyIndependenceData: {
            type: Object,
            default: null,
        },
    },
    setup(props) {
        const infoImageSize = { width: '16', height: '16' };
        const todayIndependencePercent = computed(() => formatUnitNumber(props.energyIndependenceData.today.percent, UNIT.PERCENT));
        return {
            todayIndependencePercent,
            ENERGY_VALUE_IMAGE,
            infoImageSize,
            UNIT,
        };
    },
});
