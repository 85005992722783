/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { Module } from 'vuex';

import { getRoleNm, isExpired, parseJwt, removeAccessToken, saveAccessToken } from '@hems/util/src/helper/authHelper';

import type { RootState } from '@/store';

import type { Role } from 'hems';

export interface UserInfoState {
  accessToken: string | null;
  userId: string | null;
  userNm: string | null;
  homeId: string | null;
  ownDeviceId: string | null;
  roleCd: string | null;
  roleNm: Role | null;
}

export const initState: UserInfoState = {
  accessToken: null,
  userId: null,
  userNm: null,
  homeId: null,
  ownDeviceId: null,
  roleCd: null,
  roleNm: null,
};

const userModule: Module<UserInfoState, RootState> = {
  namespaced: true,
  state: { ...initState },
  getters: {
    isAuthenticated: (state) => !!state.accessToken,
    isTokenExpired: (state) => {
      if (!state.accessToken) return true;

      const jwtObject = parseJwt(state.accessToken);

      if (!jwtObject) {
        return true;
      }

      return isExpired(jwtObject);
    },
  },
  mutations: {
    clear(state) {
      state.accessToken = null;
      state.userId = null;
      state.userNm = null;
      state.homeId = null;
      state.ownDeviceId = null;
      state.roleCd = null;
      state.roleNm = null;
    },
    setRole(state, payload: { roleCd: string; roleNm: Role }) {
      state.roleCd = payload.roleCd;
      state.roleNm = payload.roleNm;
    },
    setUserId(state, payload: string) {
      state.userId = payload;
    },
    setToken(state, payload: string) {
      state.accessToken = payload;
    },
    removeToken(state) {
      state.accessToken = null;
    },
  },
  actions: {
    setRole({ commit }, payload: { roleCd: string; roleNm: Role }) {
      commit('setRole', payload);
    },
    setUserId({ commit }, payload: string) {
      commit('setUserId', payload);
    },
    setToken({ commit }, accessToken: string) {
      saveAccessToken(accessToken);

      const jwtObject = parseJwt(accessToken);

      if (!jwtObject) {
        return null;
      }

      const userNm = jwtObject.preferred_username;
      const roleCd = jwtObject.auth_type_cd;
      const roleNm = getRoleNm(jwtObject.auth_type_cd);

      commit('setUserId', userNm);
      commit('setRole', { roleCd, roleNm });
      commit('setToken', accessToken);

      window.axiosInstance.setAccessToken(accessToken);
    },
    clear({ commit }) {
      removeAccessToken();
      window.axiosInstance.clearAccessToken();

      commit('clear');
    },
  },
};

export default userModule;
