/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { ValueType } from 'hems/common/utils';

export const HOME_INFO_TAB_NAME = {
  USER: 'user',
  INSTALLATION: 'installation',
  CONTROL: 'control',
} as const;

export type HomeInfoTabName = ValueType<typeof HOME_INFO_TAB_NAME>;

export const INSTALLATION_TAB = {
  PRODUCT: 'product',
  INSTALLER: 'installer',
  ADDRESS: 'address',
} as const;

export type InstallationTabValue = ValueType<typeof INSTALLATION_TAB>;
