
import type { PropType } from 'vue';
import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import axios from 'axios';

import { PopupWrapper, useMessageBox } from '@hems/component';
import { JoinService } from '@hems/service';
import type { AxiosErrorException } from '@hems/util/src/exception/exception';
import { getRoleCdByRoleName } from '@hems/util/src/helper/authHelper';

import type { AppCtxState } from '@/store/modules/appCtx';

import type { EnvLocale } from 'hems';

import type { Join } from 'hems/auth';
import type { SocialAccountInfo } from 'hems/auth/account';
import type { EffectiveTermsInfo } from 'hems/terms';

import Step1TermAndCondition from './Step1TermAndCondition.vue';
import Step2RequiredData from './Step2RequiredData.vue';

const API_BASE_URL = process.env.VUE_APP_API_URL;
export default defineComponent({
  name: 'InstallerJoinPopup',
  components: {
    PopupWrapper,
    Step1TermAndCondition,
    Step2RequiredData,
  },
  emits: ['close'],
  props: {
    social: Object as PropType<SocialAccountInfo>,
    locale: String as PropType<EnvLocale>,
    termsList: {
      type: Array as PropType<EffectiveTermsInfo[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const maxStep = 2;
    const store = useStore();
    const appCtx = store.state.appCtx as AppCtxState;
    const axiosInstance = axios.create({
      baseURL: API_BASE_URL,
    });
    const joinService = new JoinService(axiosInstance);
    const state = reactive({
      step: 1,
      termAndCondition: {
        termAndUse: false,
        crossBorders: false,
        privacyPolicy: false,
      } as Join.TermAndCondition,
      essentialData: {
        user_id: null,
        password: null,
        mpn_no: null,
        email: null,
        comn_nm: null,
        user_nm: null,
        auth_type_cd: getRoleCdByRoleName('installer'),
      } as Join.InstallerEssentialJoinInfo,
    });
    const isUS = appCtx.envLocale === 'US';

    function closePopup() {
      emit('close');
    }
    function onBack() {
      if (state.step <= 1) return;
      state.step -= 1;
    }
    function onNext(dataType: keyof typeof state, data: any) {
      state[dataType] = data;
      const NZTermsVer = `${process.env.VUE_APP_TERMS_VER_NZ}-NZ`;
      if (state.step >= maxStep) {
        join({
          ...state.essentialData,
          terms_ver: props.locale === 'NZ' ? NZTermsVer : `${appCtx.termsVer}-${appCtx.envLocale}`,
        });

        return;
      }
      state.step += 1;
    }

    function join(params: Join.InstallerJoinParam) {
      if (!validate(params)) return;
      joinService
        .joinInstaller(params, props.social)
        .then(() => {
          emit('close');
          messageBox.alert(t('message.success_sign_up')).open();
        })
        .catch((e: AxiosErrorException) => {
          const { message, message_code } = e.originError.response?.data;
          if (message_code && message) {
            messageBox.alert(t(message_code, { defaultValue: message })).open();

            return;
          }
          if (message) {
            messageBox.alert(message).open();

            return;
          }
          messageBox.alert(t('message.unknown_error')).open();
        });
    }

    function validate(params: Join.InstallerJoinParam): boolean {
      const { termAndUse, crossBorders, privacyPolicy } = state.termAndCondition;
      if (isUS) {
        if (!termAndUse || !privacyPolicy) {
          messageBox.alert(t('message.agree_terms')).open();

          return false;
        }
      } else {
        if (!termAndUse || !crossBorders || !privacyPolicy) {
          messageBox.alert(t('message.agree_terms')).open();

          return false;
        }
      }

      const { user_id, password, mpn_no, email, comn_nm } = params;
      if (!mpn_no || !user_id || (!props.social && !password) || !email || !comn_nm) {
        messageBox.alert(t('message.req_value_missing')).open();

        return false;
      }

      return true;
    }

    return {
      state,
      closePopup,
      onBack,
      onNext,
    };
  },
});
