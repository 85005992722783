/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { ValueType } from 'hems/common/utils';

export const IMAGE_DESCRIPTION_TYPE = {
  INFO: 'info',
  WARN: 'warn',
  NOTICE: 'notice',
} as const;

export type ImageDescriptionType = ValueType<typeof IMAGE_DESCRIPTION_TYPE>;

export const IMAGE_DESCRIPTION_COLOR = {
  [IMAGE_DESCRIPTION_TYPE.INFO]: '#f6f6f6',
  [IMAGE_DESCRIPTION_TYPE.WARN]: '#FEECED',
  [IMAGE_DESCRIPTION_TYPE.NOTICE]: '#fff9f3',
} as const;

export const IMAGE_DESCRIPTION_ICON = {
  [IMAGE_DESCRIPTION_TYPE.INFO]: 'svg/ic_re_info_default',
  [IMAGE_DESCRIPTION_TYPE.WARN]: 'svg/ic_error',
  [IMAGE_DESCRIPTION_TYPE.NOTICE]: 'svg/ic_re_info',
} as const;
