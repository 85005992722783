import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "banner-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`banner-container banner-container-${_ctx.bannerType}`)
  }, [
    _createVNode(_component_Image2, {
      class: "banner-icon",
      src: _ctx.iconSrc,
      extension: "svg"
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.contents), 1)
  ], 2))
}