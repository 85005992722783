
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoginButton',
  props: {
    isLogin: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
});
