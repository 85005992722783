import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = {
  key: 0,
  class: "main-header-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createVNode(_component_Image, {
            class: "main-header-logo",
            src: "main/header-logo",
            extension: "svg"
          })
        ]),
        _: 1
      }),
      (_ctx.showMenu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Image, {
              class: _normalizeClass(`menu-icon ${_ctx.isOpenLanguageMenu && 'is-active'}`),
              src: "icon/ic_globe",
              extension: "svg",
              onClick: _ctx.toggleLanguageMenu
            }, null, 8, ["class", "onClick"]),
            _createVNode(_component_Image, {
              class: _normalizeClass(`menu-icon ${_ctx.isOpenAccountMenu && 'is-active'}`),
              src: "icon/ic_user_account",
              extension: "svg",
              onClick: _ctx.toggleAccountMenu
            }, null, 8, ["class", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isOpenLanguageMenu)
      ? (_openBlock(), _createBlock(_component_DropdownMenu, {
          key: 0,
          ref: "languageMenuRef",
          "selected-value": _ctx.selectedLanguage,
          class: "header-menu",
          items: _ctx.languageDropdownItems,
          onClick: _ctx.onClickLanguage
        }, null, 8, ["selected-value", "items", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.isOpenAccountMenu)
      ? (_openBlock(), _createBlock(_component_DropdownMenu, {
          key: 1,
          ref: "accountMenuRef",
          class: "header-menu",
          items: _ctx.accountDropdownItems,
          onClick: _ctx.onClickAccountMenu
        }, null, 8, ["items", "onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}