/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import isEmpty from 'lodash-es/isEmpty';
import pick from 'lodash-es/pick';
import uniq from 'lodash-es/uniq';
import { SORT_ORDER } from '@hems/util/src/constant';
import { ERROR_LEVEL, ERROR_LEVEL_DISPLAY_TEXT, ERROR_PREFIX, ERROR_STATUS_TYPE, ERROR_RESOLVED_FILTER, } from '@hems/util/src/constant/eventHistory';
import { LANGUAGE } from '@hems/util/src/constant/languages';
import { defaultPeriod, defaultStartDateTimeOptions, extractDateWithoutOffset, getDateWithAdjustedTime, getLocalDate, } from '@hems/util/src/helper/dateHelper';
import { addSortOrderMark, isEmptyArray, isNull } from '@hems/util/src/helper/helper';
import { getStringValue } from '@hems/util/src/helper/tsguardHelper';
const dateFormatConfig = { isTime: true, needUtcConvert: true };
export const getErrorHistoryParams = (condition, language) => {
    const { pageNumber, listSize, sort, search, date = defaultPeriod, errorLevel, excludeErrorCodes, errorResolved, } = condition;
    const commonParams = {
        pageNumber,
        listSize,
        sort: getStringValue(sort),
        search: getStringValue(search),
        language,
        cleared: errorResolved === ERROR_RESOLVED_FILTER.RESOLVED,
        errorLevel: getStringValue(errorLevel),
        excludeErrorCodes: getStringValue(excludeErrorCodes),
    };
    if (errorResolved === ERROR_RESOLVED_FILTER.CURRENT) {
        return commonParams;
    }
    return {
        ...commonParams,
        clearDateStart: getDateWithAdjustedTime(date?.start, defaultStartDateTimeOptions).toISOString(),
    };
};
export const getErrorData = (errorList, totalCount) => {
    return {
        data: errorList.map((errorInfo) => ({
            ...errorInfo,
            deviceId: getDeviceId(errorInfo.deviceId, errorInfo.subDeviceId),
            triggerDate: errorInfo.triggerDate
                ? getLocalDate(extractDateWithoutOffset(errorInfo.triggerDate), dateFormatConfig)
                : '',
            clearDate: errorInfo.clearDate
                ? getLocalDate(extractDateWithoutOffset(errorInfo.clearDate), dateFormatConfig)
                : '',
            triggerDateUtc: errorInfo.triggerDate ?? null,
            clearDateUtc: errorInfo.clearDate ?? null,
            errorLevel: getErrorLevel(errorInfo.errorLevel),
            description: getDescription(errorInfo.display),
        })),
        totalCount,
    };
};
export const getRecentErrorData = (errorList) => {
    return errorList.map((error) => ({
        siteId: error.siteId,
        deviceId: getDeviceId(error.deviceId, error.subDeviceId),
        errorLevel: getErrorLevel(error.errorLevel),
        triggerDate: getLocalDate(extractDateWithoutOffset(error.triggerDate), dateFormatConfig),
        errorCode: error.errorCode,
        // TODO: 기존의 recent event history에서 서버에서 주는 값이 항상 'Y'인지 확인 필요
        newFlag: 'Y',
    }));
};
export const getSiteErrorParams = (siteId, language = LANGUAGE.EN) => {
    return {
        sort: addSortOrderMark('triggerDate', SORT_ORDER.DESCENDING),
        language,
        cleared: false,
        search: siteId,
    };
};
export const getSiteErrorData = (errorList) => {
    const errorCodeAndDate = errorList.map((error) => ({
        ...error,
        triggerDate: getLocalDate(extractDateWithoutOffset(error.triggerDate), dateFormatConfig),
    }));
    const siteErrors = {};
    Object.values(ERROR_PREFIX).forEach((prefix) => {
        const errorsByPrefix = getErrorInfoByPrefix(errorCodeAndDate, prefix);
        siteErrors[prefix] = { errorStatusType: getSiteErrorStatusType(errorsByPrefix), errorInfo: errorsByPrefix };
    });
    return siteErrors;
};
const getDescription = (display) => {
    return getStringValue(display.description) ?? '';
};
const getErrorLevel = (errorLevel) => {
    switch (errorLevel) {
        case ERROR_LEVEL.FAULT:
            return ERROR_LEVEL_DISPLAY_TEXT.FAULT;
        case ERROR_LEVEL.WARNING:
            return ERROR_LEVEL_DISPLAY_TEXT.WARNING;
        case ERROR_LEVEL.ALARM:
            return ERROR_LEVEL_DISPLAY_TEXT.ALARM;
        default:
            return null;
    }
};
const getDeviceId = (deviceId, subDeviceId) => {
    if (isNull(subDeviceId)) {
        return deviceId;
    }
    return `${deviceId}(${subDeviceId})`;
};
const getErrorInfoByPrefix = (errors, prefix) => {
    return errors.filter((error) => error.errorCode.startsWith(prefix));
};
export const getSiteErrorStatusType = (errors) => {
    const faults = errors.some((error) => error.errorLevel === ERROR_LEVEL.FAULT);
    const warnings = errors.some((error) => error.errorLevel === ERROR_LEVEL.WARNING);
    if (faults) {
        return ERROR_STATUS_TYPE.ERROR;
    }
    if (warnings) {
        return ERROR_STATUS_TYPE.WARNING;
    }
    return null;
};
const getDeviceIdFilter = (errors, deviceId) => {
    let errorInfos = errors?.errorInfo;
    if (deviceId) {
        errorInfos = errors?.errorInfo?.filter((error) => error.deviceId === deviceId);
    }
    return {
        errorStatusType: getSiteErrorStatusType(errorInfos ?? []),
        errorInfo: errorInfos ?? [],
    };
};
export const getErrorCodeAndDateForAC = (errors, deviceId) => {
    if (!errors || isEmpty(errors.errorInfo)) {
        return [];
    }
    const errorCodeAndDate = uniq(getDeviceIdFilter(errors, deviceId).errorInfo?.map((error) => ({
        errorCode: error.errorCode,
        triggerDate: error.triggerDate,
    })));
    return errorCodeAndDate;
};
export const getErrorCodeAndDateForACFromArray = (errorArray, device_id) => {
    const isErrorCodesEmpty = errorArray.every((item) => item?.errorInfo?.length === 0);
    if (isErrorCodesEmpty) {
        return [];
    }
    const errors = errorArray
        .map((item) => getErrorCodeAndDateForAC(item, device_id))
        .filter((item) => !isEmptyArray(item));
    return errors.flat();
};
export const getErrorStatusTypeFromArray = (errorArray, deviceId) => {
    const isErrorCodesEmpty = errorArray.every((item) => item?.errorInfo?.length === 0);
    if (isErrorCodesEmpty) {
        return;
    }
    const errorStatusTypeByArray = errorArray.map((item) => getDeviceIdFilter(item, deviceId).errorStatusType);
    if (errorStatusTypeByArray.includes(ERROR_STATUS_TYPE.ERROR)) {
        return ERROR_STATUS_TYPE.ERROR;
    }
    if (errorStatusTypeByArray.includes(ERROR_STATUS_TYPE.WARNING)) {
        return ERROR_STATUS_TYPE.WARNING;
    }
    return;
};
/** GEN2, GEN3, FOX 장비의 에러 코드 이름과 에러 발생 날짜를 String으로 변환 */
export const getErrorCodeAndDate = (codes, errorMap) => {
    if (!errorMap) {
        return [];
    }
    const splitCodes = codes?.split(',');
    const codesAndDates = pick(errorMap, splitCodes);
    const mappedErrorList = Object.entries(codesAndDates).map(([code, value]) => {
        const errorDate = `${getLocalDate(value, { isTime: true, needUtcConvert: true })}`;
        return { errorCode: code, triggerDate: errorDate };
    });
    return mappedErrorList;
};
