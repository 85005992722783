import { DeviceService } from '@hems/service/src/api/device/DeviceService';
import { apiWrapper, getApiVersionHeader } from '@hems/service/src/util/helper';
import { Helper } from '@hems/util';
import { getEnvLocale } from '@hems/util/src/helper/helper';
export default class DeviceServiceUser extends DeviceService {
    constructor(axios) {
        super(axios);
    }
    async getUserInstallationInfo() {
        const isUS = getEnvLocale() === 'US';
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/information'), undefined, {
            headers: getApiVersionHeader(isUS),
        });
        if (Array.isArray(data.result)) {
            if (data.result.length >= 2) {
                return data;
            }
            return {
                ...data,
                result: Helper.isEmptyArray(data.result) ? null : data.result[0],
            };
        }
        return data;
    }
    async getSiteId() {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/site-id'));
        return data;
    }
    /** AC System Configuration Monitoring */
    async getUserACSystemSettingInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/ac/user/${siteId}/configurations`));
        return data;
    }
}
