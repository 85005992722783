import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b147a77"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box intaller" }
const _hoisted_2 = { class: "box-inner" }
const _hoisted_3 = { class: "tit" }
const _hoisted_4 = { class: "guide" }
const _hoisted_5 = { class: "bul-txt" }
const _hoisted_6 = { class: "btn-area" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('account.join_install_member')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(`${_ctx.$t('message.installer_deal_qcell_product')} ${_ctx.$t('message.contact_technical_support')}`), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "btn_s_3 btn_c",
          disabled: _ctx.isAU && _ctx.isNull(_ctx.state.selectedLocale),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickJoin && _ctx.onClickJoin(...args)))
        }, _toDisplayString(_ctx.$t('account.join_install_member')), 9, _hoisted_7)
      ])
    ])
  ]))
}